const atob = require('atob')

function b64DecodeUnicode (str) {
  return decodeURIComponent(atob(str).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))
}

const mutations = {
  setCity (state, data) {
    state.currentCity = data.name
    state.currentRegion = data.region
  },
  setGeoInformation (state, data) {
    state.currentCity = data.hasOwnProperty('x-city') ? b64DecodeUnicode(data['x-city']) : 'Москва'
    state.currentRegion = data.hasOwnProperty('x-region') ? data['x-region'] : 'Московская обл.'
    state.currentCountry = data.hasOwnProperty('x-country') ? data['x-country'] : 'RU'
  }
}

export default mutations
