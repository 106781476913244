import { Module } from 'vuex'
import getters from './getters'
import actions from './actions'
import mutations from './mutations'
import CartState from '../types/CartState'

export const module: Module<CartState, any> = {
  state: {
    isMicrocartOpen: false,
    itemsAfterPlatformTotals: {},
    platformTotals: null,
    platformTotalSegments: null,
    cartIsLoaded: false,
    cartServerPullAt: 0,
    cartServerTotalsAt: 0,
    cartServerCreatedAt: 0,
    cartServerMethodsRefreshAt: 0,
    cartServerBypassAt: 0,
    cartSavedAt: Date.now(),
    bypassToAnon: false,
    cartServerToken: '',
    shipping: [],
    payment: [],
    cartItemsHash: '',
    bypassCount: 0,
    cartItems: [],
    cartIsLoading: false
  },
  getters,
  mutations,
  actions
}
