import * as types from './mutation-types'
import { ActionTree } from 'vuex'
import LandingPagesState from '../types/LandingPagesState'
import RootState from 'core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import fetch from 'isomorphic-fetch'

const actions: ActionTree<LandingPagesState, RootState> = {
  loadLandingPageData (context, route) {
    let url = rootStore.state.config.landingPages.endpoint
    if (!url.startsWith('http') && typeof window === 'undefined') {
      url = `${rootStore.state.config.api.internalHost}${url}`
    }
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ route })
    })
      .then(resp => resp.json())
      .then(landingPagesdata => {
        const landing_pages = landingPagesdata.result && landingPagesdata.result.id ? landingPagesdata.result : null
        context.commit(types.LANDING_PAGES_SET, landing_pages)
      })
  }
}

export default actions
