import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from '@vue-storefront/core/modules/catalog/store/category/mutation-types'
import * as localTypes from './mutation-types'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../../types/CategoryState'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import i18n from '@vue-storefront/i18n'
import chunk from 'lodash-es/chunk'
import toString from 'lodash-es/toString'
import {optionLabel} from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import trim from 'lodash-es/trim'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { entityKeyName } from '@vue-storefront/core/store/lib/entities'
import fetch from 'isomorphic-fetch'
import {baseFilterProductsQuery, scriptForTranslate} from '@vue-storefront/kt-theme/helpers'

const actions: ActionTree<CategoryState, RootState> = {
  single (context, { key, value, setCurrentCategory = true, setCurrentCategoryPath = true, populateRequestCacheTags = true }) {
    const state = context.state
    const commit = context.commit
    const dispatch = context.dispatch

    return new Promise<void>((resolve, reject) => {
      let setcat = (error, mainCategory) => {
        if (error) {
          console.error(error)
          reject(error)
        }

        if (setCurrentCategory) {
          commit(types.CATEGORY_UPD_CURRENT_CATEGORY, mainCategory)
        }
        if (populateRequestCacheTags && mainCategory && Vue.prototype.$ssrRequestContext) {
          Vue.prototype.$ssrRequestContext.output.cacheTags.add(`C${mainCategory.id}`)
        }
        if (setCurrentCategoryPath) {
          let currentPath = []
          let recurCatFinder = (category) => {
            if (!category) {
              return
            }
            if (category.parent_id) {
              dispatch('single', { key: 'id', value: category.parent_id, setCurrentCategory: false, setCurrentCategoryPath: false }).then((sc) => {
                if (!sc) {
                  commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
                  Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
                  return resolve(mainCategory)
                }
                currentPath.unshift(sc)
                if (sc.parent_id) {
                  recurCatFinder(sc)
                }
              }).catch(err => {
                console.error(err)
                commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath) // this is the case when category is not binded to the root tree - for example 'Erin Recommends'
                resolve(mainCategory)
              })
            } else {
              commit(types.CATEGORY_UPD_CURRENT_CATEGORY_PATH, currentPath)
              Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
              resolve(mainCategory)
            }
          }
          if (typeof mainCategory !== 'undefined') {
            recurCatFinder(mainCategory)
          } else {
            reject(new Error('Category query returned empty result ' + key + ' = ' + value))
          }
        } else {
          Vue.prototype.$bus.$emit('category-after-single', { category: mainCategory })
          resolve(mainCategory)
        }
      }

      if (state.list.length > 0) { // SSR - there were some issues with using localForage, so it's the reason to use local state instead, when possible
        let category = state.list.find((itm) => { return itm[key] === value })
        // Check if category exists in the store OR we have recursively reached Default category (id=1)
        if (category || value === 1) {
          setcat(null, category)
        } else if (key === 'id') {
          resolve()
        } else {
          resolve()
          // Тут категория отдает 404, если она не найдена
          // return reject(new Error('Category query returned empty result ' + key + ' = ' + value))
        }
      } else {
        const catCollection = Vue.prototype.$db.categoriesCollection
        // Check if category does not exist in the store AND we haven't recursively reached Default category (id=1)
        if (!catCollection.getItem(entityKeyName(key, value), setcat) && value !== 1) {
          reject(new Error('Category query returned empty result ' + key + ' = ' + value))
        }
      }
    })
  },
  list (context, { parent = null, onlyActive = true, onlyNotEmpty = false, size = 4000, start = 0, sort = 'position:asc', includeFields = rootStore.state.config.entities.optimize ? rootStore.state.config.entities.category.includeFields : null, skipCache = false }) {
    const commit = context.commit

    let searchQuery = new SearchQuery()
    if (parent && typeof parent !== 'undefined') {
      searchQuery = searchQuery.applyFilter({key: 'parent_id', value: {'eq': parent.id}})
    }

    if (onlyActive === true) {
      searchQuery = searchQuery.applyFilter({key: 'is_active', value: {'eq': true}})
    }

    if (onlyNotEmpty === true) {
      searchQuery = searchQuery.applyFilter({key: 'product_count', value: {'gt': 0}})
    }

    if (skipCache || (!context.state.list || context.state.list.length === 0)) {
      return quickSearchByQuery({ entityType: 'category', query: searchQuery, sort: sort, size: size, start: start, includeFields: includeFields }).then((resp) => {
        commit(types.CATEGORY_UPD_CATEGORIES, resp)
        Vue.prototype.$bus.$emit('category-after-list', { query: searchQuery, sort: sort, size: size, start: start, list: resp })
        return resp
      })
    } else {
      return new Promise((resolve) => {
        let resp = { items: context.state.list, total: context.state.list.length }
        Vue.prototype.$bus.$emit('category-after-list', { query: searchQuery, sort: sort, size: size, start: start, list: resp })
        resolve(resp)
      })
    }
  },
  products (context, { activeBq = null, customAggregations = false, populateAggregations = false, filters = [], searchProductQuery, current = 0, perPage = 50, sort = '', includeFields = null, excludeFields = null, configuration = null, append = false, skipCache = false, banners = [], updateProductState = true, isPresentsPage = false }) {
    rootStore.state.category.current_product_query = {
      populateAggregations,
      filters,
      current,
      perPage,
      includeFields,
      excludeFields,
      configuration,
      append,
      sort
    }

    let prefetchGroupProducts = true
    if (rootStore.state.config.entities.twoStageCaching && rootStore.state.config.entities.optimize && !Vue.prototype.$isServer && !rootStore.state.twoStageCachingDisabled) { // only client side, only when two stage caching enabled
      includeFields = rootStore.state.config.entities.productListWithChildren.includeFields // we need configurable_children for filters to work
      excludeFields = rootStore.state.config.entities.productListWithChildren.excludeFields
      prefetchGroupProducts = false
      console.log('Using two stage caching for performance optimization - executing first stage product pre-fetching')
    } else {
      prefetchGroupProducts = true
      if (rootStore.state.twoStageCachingDisabled) {
        console.log('Two stage caching is disabled runtime because of no performance gain')
      } else {
        console.log('Two stage caching is disabled by the config')
      }
    }
    let t0 = new Date().getTime()

    const precachedQuery = searchProductQuery
    let productPromise = rootStore.dispatch('product/list', {
      query: precachedQuery,
      start: current,
      size: perPage,
      excludeFields: excludeFields,
      includeFields: includeFields,
      configuration: configuration,
      append: append,
      sort: sort,
      updateState: updateProductState,
      prefetchGroupProducts: prefetchGroupProducts,
      banners: banners,
      isPresentsPage: isPresentsPage
    }).then((res) => {
      let t1 = new Date().getTime()
      rootStore.state.twoStageCachingDelta1 = t1 - t0

      let subloaders = []
      if (!res || (res.noresults)) {
        rootStore.dispatch('notification/spawnNotification', {
          type: 'warning',
          message: i18n.t('No products synchronized for this category. Please come back while online!'),
          action1: { label: i18n.t('OK') }
        })
        if (!append) rootStore.dispatch('product/reset')
        rootStore.state.product.list = { items: [] } // no products to show TODO: refactor to rootStore.state.category.reset() and rootStore.state.product.reset()
        // rootStore.state.category.filters = { color: [], size: [], price: [] }
        return []
      } else {
        if (rootStore.state.config.products.filterUnavailableVariants && rootStore.state.config.products.configurableChildrenStockPrefetchStatic) { // prefetch the stock items
          const skus = []
          let prefetchIndex = 0
          res.items.map(i => {
            if (rootStore.state.config.products.configurableChildrenStockPrefetchStaticPrefetchCount > 0) {
              if (prefetchIndex > rootStore.state.config.products.configurableChildrenStockPrefetchStaticPrefetchCount) return
            }
            skus.push(i.sku) // main product sku to be checked anyway
            if (i.type_id === 'configurable' && i.configurable_children && i.configurable_children.length > 0) {
              for (const confChild of i.configurable_children) {
                const cachedItem = context.rootState.stock.cache[confChild.id]
                if (typeof cachedItem === 'undefined' || cachedItem === null) {
                  skus.push(confChild.sku)
                }
              }
              prefetchIndex++
            }
          })
          for (const chunkItem of chunk(skus, 15)) {
            rootStore.dispatch('stock/list', { skus: chunkItem, skipCache }) // store it in the cache
          }
        }
        let tmpAvailableFilter = {} // set active filters
        if (updateProductState && res.aggregations?.agg_avg_rating?.value) {
          context.dispatch('updShowReviewsBtn', true)
        }
        if ((populateAggregations === true || customAggregations === true) && res.aggregations) { // populate filter aggregates
          for (let attrToFilter of filters) { // fill out the filter options
            const available = rootStore.state.category.filters.available
            const stableFilter = rootStore.state.config.products.stableFilter
            if (
              (activeBq && available[stableFilter] && attrToFilter === stableFilter) ||
              // (activeBq && attrToFilter === activeBq.attribute_code && activeBq.active) ||
              (
                activeBq &&
                attrToFilter === activeBq.attribute_code &&
                available[attrToFilter] &&
                res.aggregations['agg_terms_' + attrToFilter] &&
                available[attrToFilter].length > res.aggregations['agg_terms_' + attrToFilter].buckets.length
              )
            ) {
              continue
            }
            let uniqueFilterValues = new Set<any>()
            let normalizeAttrToFilter = attrToFilter
            if (attrToFilter.indexOf('translate') !== -1) {
              normalizeAttrToFilter = attrToFilter.replace('_translate', '.keyword') // приводим к обычному виду название фильтра
            }
            if (attrToFilter !== 'price') {
              tmpAvailableFilter[normalizeAttrToFilter] = []
              const lengthResItems = res.items && res.items.length
              if (res.aggregations['agg_terms_' + attrToFilter]) {
                let buckets = res.aggregations['agg_terms_' + attrToFilter].buckets // используем обычное значение, чтоб получить buckets запроса
                if (res.aggregations['agg_terms_' + attrToFilter + '_options']) {
                  buckets = buckets.concat(res.aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
                }
                for (let option of buckets) {
                  uniqueFilterValues.add([toString(option.key), option['doc_count']])
                }
                if (lengthResItems === 0 || !parseInt(perPage)) {
                  context.commit(localTypes.CATEGORY_SET_GENERAL_AGG_OF_CATEGORY, {[normalizeAttrToFilter]: buckets}) // set all filters on first request
                }
              }
              const generalAggOfCategory = context.state.generalAggOfCategory
              uniqueFilterValues.forEach(key => {
                let [origin, translate = origin.toLowerCase().replace(' ', '-').replace(/'/g, '')] = key[0].split(',')
                translate = attrToFilter !== 'bottle_volume_options' ? translate : ''
                const label = optionLabel(rootStore.state.attribute, { attributeKey: attrToFilter, optionId: origin })
                if (trim(label) !== '' && (lengthResItems || perPage)) { // is there any situation when label could be empty and we should still support it?
                  tmpAvailableFilter[normalizeAttrToFilter].push({
                    id: origin,
                    count: key[1],
                    label: origin,
                    translate
                  })
                }
              })
              if (lengthResItems || perPage) {
                const filterOptions = generalAggOfCategory[normalizeAttrToFilter] // get filter by key from all filters
                const chosenFilter = Object.keys(context.state.filters.chosen).filter(chosen => chosen !== 'price')
                filterOptions?.forEach(option => {
                  const [origin, translate] = option.key.split(',')
                  const findActualFilterOption = tmpAvailableFilter[normalizeAttrToFilter] // get filter by key from selected filter
                  const label = optionLabel(rootStore.state.attribute, { attributeKey: normalizeAttrToFilter, optionId: origin })
                  const normalizedFilterOption = {
                    id: origin,
                    count: option.doc_count,
                    label: label,
                    translate
                  }
                  const findValue = findActualFilterOption && findActualFilterOption.find(aFilter => toString(aFilter.id) === toString(normalizedFilterOption.id))
                  if (findValue) {
                    context.dispatch('setAvailableFilters', {key: normalizeAttrToFilter, value: findValue}) // сет найденых фильтров
                  } else if (chosenFilter && chosenFilter.length === 1 && chosenFilter[0] === normalizeAttrToFilter) {
                    context.dispatch('setAvailableFilters', {key: normalizeAttrToFilter, value: normalizedFilterOption}) // сет всех значений для первого выбранного фильтра
                  } else {
                    context.dispatch('setAvailableFilters', {key: normalizeAttrToFilter, value: {...normalizedFilterOption, count: 0}}) // сет 0 значений для всех невозможных фильтров
                  }
                })
              }
            } else { // special case is range filter for prices
              const storeView = currentStoreView()
              const currencySign = storeView.i18n.currencySign
              if (res.aggregations['agg_range_' + attrToFilter]) {
                let index = 0
                let count = res.aggregations['agg_range_' + attrToFilter].buckets.length
                for (let option of res.aggregations['agg_range_' + attrToFilter].buckets) {
                  context.dispatch('setAvailableFilters', {key: attrToFilter,
                    value: {
                      maxPrice: res.aggregations['agg_max_price'] && res.aggregations['agg_max_price'].value ? res.aggregations['agg_max_price'].value : null,
                      minPrice: res.aggregations['agg_min_price'] && res.aggregations['agg_min_price'].value ? res.aggregations['agg_min_price'].value : null,
                      id: option.key,
                      from: option.from,
                      to: option.to,
                      label: (index === 0 || (index === count - 1)) ? (option.to ? '< ' + currencySign + option.to : '> ' + currencySign + option.from) : currencySign + option.from + (option.to ? ' - ' + option.to : '')// TODO: add better way for formatting, extract currency sign
                    }}) // сет 0 значений для всех невозможных фильтров
                  index++
                }
              }
            }
          }
        }
      }
      return subloaders
    }).catch((err) => {
      console.error(err)
      rootStore.dispatch('notification/spawnNotification', {
        type: 'warning',
        message: i18n.t('No products synchronized for this category. Please come back while online!'),
        action1: { label: i18n.t('OK') }
      })
    })

    if (rootStore.state.config.entities.twoStageCaching && rootStore.state.config.entities.optimize && !Vue.prototype.$isServer && !rootStore.state.twoStageCachingDisabled) { // second stage - request for caching entities
      console.log('Using two stage caching for performance optimization - executing second stage product caching') // TODO: in this case we can pre-fetch products in advance getting more products than set by pageSize
      rootStore.dispatch('product/list', {
        query: precachedQuery,
        start: current,
        size: perPage,
        excludeFields: null,
        includeFields: null,
        updateState: false, // not update the product listing - this request is only for caching
        isPresentsPage: isPresentsPage
      }).catch((err) => {
        console.info("Problem with second stage caching - couldn't store the data")
        console.info(err)
      }).then(() => {
        let t2 = new Date().getTime()
        rootStore.state.twoStageCachingDelta2 = t2 - t0
        console.log('Using two stage caching for performance optimization - Time comparison stage1 vs stage2', rootStore.state.twoStageCachingDelta1, rootStore.state.twoStageCachingDelta2)
        if (rootStore.state.twoStageCachingDelta1 > rootStore.state.twoStageCachingDelta2) { // two stage caching is not making any good
          rootStore.state.twoStageCachingDisabled = true
          console.log('Disabling two stage caching')
        }
      })
    }
    return productPromise
  },
  loadFilterInHeader ({dispatch}, {subcategory, filters}) {
    const query = baseFilterProductsQuery({parentCategory: subcategory, filters, scope: 'header', scriptForES: scriptForTranslate})
    return rootStore.dispatch('product/list', {query, size: 0}).then(res => {
      if (res.aggregations) {
        for (let attrToFilter of filters) {
          const filterOptions = []
          let uniqueFilterValues = new Set<string>()
          let normalizeAttrToFilter = attrToFilter
          if (attrToFilter.indexOf('translate') !== -1) {
            normalizeAttrToFilter = attrToFilter.replace('_translate', '.keyword') // приводим к обычному виду название фильтра
          }
          if (attrToFilter !== 'price') {
            if (res.aggregations['agg_terms_' + attrToFilter]) {
              let buckets = res.aggregations['agg_terms_' + attrToFilter].buckets
              if (res.aggregations['agg_terms_' + attrToFilter + '_options']) {
                buckets = buckets.concat(res.aggregations['agg_terms_' + attrToFilter + '_options'].buckets)
              }
              for (let option of buckets) {
                uniqueFilterValues.add(toString(option.key))
              }
            }
            uniqueFilterValues.forEach(key => {
              let [origin, translate = origin.toLowerCase().replace(' ', '-').replace(/'/g, '')] = key.split(',')
              translate = attrToFilter !== 'bottle_volume_options' ? translate : ''
              const label = optionLabel(rootStore.state.attribute, { attributeKey: normalizeAttrToFilter, optionId: origin })
              if (trim(label) !== '') {
                filterOptions.push({
                  id: origin,
                  label: origin,
                  slug: subcategory.slug,
                  translate
                })
              }
            })
          } else { // special case is range filter for prices
            const storeView = currentStoreView()
            const currencySign = storeView.i18n.currencySign
            if (res.aggregations['agg_range_' + attrToFilter]) {
              let index = 0
              let count = res.aggregations['agg_range_' + attrToFilter].buckets.length
              for (let option of res.aggregations['agg_range_' + attrToFilter].buckets) {
                if (option.doc_count) {
                  filterOptions.push({
                    id: option.key.replace('*', res.aggregations['agg_max_price'].value),
                    from: option.from,
                    to: option.to,
                    label: (index === 0 || (index === count - 1)) ? (option.to ? 'До ' + option.to + currencySign : 'Более ' + option.from + currencySign) : option.from + (option.to ? ' - ' + option.to : '') + currencySign, // TODO: add better way for formatting, extract currency sign
                    slug: subcategory.slug
                  })
                  index++
                }
              }
            }
          }
          dispatch('addAvailabelHeaderFilters', {
            slug: subcategory.slug,
            key: normalizeAttrToFilter,
            options: filterOptions
          })
        }
      }
    })
  },
  loadBestProduct ({commit}, {subcategory}) {
    commit(localTypes.CATEGORY_ADD_HEADER_BEST_PRODUCT, [])
    const popularProducts = subcategory && subcategory.popular_products
    if (popularProducts) {
      const skus = [...popularProducts].sort((x, y) => {
        return x.position - y.position
      }).map(product => product.sku).filter(v => !!v)
      const query = baseFilterProductsQuery({parentCategory: subcategory, scope: 'header'})
      query.applyFilter({key: 'sku', value: {'in': skus}})
      rootStore.dispatch('product/list', {query, size: 10}).then(res => {
        if (res.items) {
          const sortedProduct = skus.map(sku => {
            return res.items.find(item => item.sku === sku)
          }).filter(v => !!v)
          commit(localTypes.CATEGORY_ADD_HEADER_BEST_PRODUCT, sortedProduct || [])
        }
      })
    }
  },
  singleByHash (context, { hash }) {
    return new Promise((resolve, reject) => {
      if (hash) {
        let url = `${rootStore.state.config.vipCategoryPage.endpoint}/single/${hash}`
        if (!url.startsWith('http') && typeof window === 'undefined') {
          url = `${rootStore.state.config.api.internalHost}${url}`
        }
        return fetch(url, {
          method: 'GET'
        }).then(result => {
          return result.json()
        }).then(result => {
          if (result && result.code === 200 && result.result && result.result.length) {
            const category = result.result[0]
            if (category.id) {
              context.commit(types.CATEGORY_UPD_CURRENT_CATEGORY, category)
              return resolve(category)
            }
            reject(new Error('Category query returned empty result'))
          }
        }).catch(err => {
          reject(new Error(err))
        })
      } else {
        reject(new Error('Hash is not defined'))
      }
    })
  },
  resetAvailableFilters (context) {
    context.commit(localTypes.CATEGORY_REMOVE_AVAILABLE_FILTERS)
  },
  updateChosenFilters (context, filters) {
    context.commit(localTypes.CATEGORY_UPDATE_CHOSEN_FILTER, filters)
  },
  addAvailabelHeaderFilters ({commit}, {slug, key, options} = {}) {
    if (key && slug) commit(localTypes.CATEGORY_ADD_HEADER_FILTER, {slug, key, options})
  },
  setSubcategoryForPopup ({commit}, subcategory) {
    commit(localTypes.CATEGORY_SET_SUBCATEGORY_FOR_POPUP, subcategory)
  },
  resetGeneralAggOfCategory ({commit}) {
    commit(localTypes.CATEGORY_REMOVE_GENERAL_AGG_OF_CATEGORY)
  },
  setAvailableFilters ({commit}, {key, value}) {
    commit(localTypes.CATEGORY_ADD_CATEGORY_AVAILABLE_FILTER, {key, value})
  },
  updShowReviewsBtn ({commit}, isShowBtn) {
    commit(localTypes.UPD_SHOW_REVIEWS_BTN, isShowBtn)
  }
}

export default actions
