import { isServer } from '@vue-storefront/core/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import { hasValue } from './hasValue'
import rootStore from '@vue-storefront/core/store'

export const isClient = () => {
  if (isServer) {
    Logger.error('Process on server side, GTM is not available')()
    return false
  }
  return true
}

export const checkForRequiredFields = (requiredField = [], object) => {
  if (!Array.isArray(requiredField)) {
    return false
  }

  for (let field of requiredField) {
    if (!(field in object)) {
      Logger.error(`Missing required field - ${field}`)()
      return false
    }
  }

  return true
}

const prepareCategories = (categories) => {
  if (!categories || !Array.isArray(categories)) {
    return ''
  }

  let categoryList = categories.map(itm => itm.name).slice(0, 5)
  return categoryList.join('/')
}

const prepareVariant = (volume) => {
  const configVolumes = rootStore.state.config.bottleValues
  const currentVolume = volume
  return configVolumes[currentVolume] ? `${configVolumes[currentVolume]}л` : ''
}

export const prepareProducts = (products, isQuantity = true) => {
  let preparedProducts = []

  if (!products || !Array.isArray(products)) {
    return preparedProducts
  }
  for (let item of products) {
    preparedProducts = [...preparedProducts]
    let productData = {
      name: item.name || '',
      id: item.id || item.product_id || '',
      price: item.price || 0,
      brand: item.bq_4740 || '',
      variant: prepareVariant(item.bottle_volume) || '',
      category: prepareCategories(item.category)
    }
    if (isQuantity) {
      productData = Object.assign({}, productData, {
        quantity: item.qty || item.qty_ordered || ''
      })
    }
    preparedProducts.push(productData)
  }

  return preparedProducts
}

export const shippingMethod = (order) => {
  let shippingMethod = ''

  if (!order) {
    return shippingMethod
  }

  if (hasValue(order, ['extension_attributes', 'shipping_assignments']) && order.extension_attributes.shipping_assignments.length) {
    const shippingInfo = order.extension_attributes.shipping_assignments[0]
    shippingMethod = hasValue(shippingInfo, ['shipping', 'method']) ? shippingInfo.shipping.method : ''
  }

  return shippingMethod
}

export const deliveryDate = (order) => {
  let deliveryDate = ''

  if (!order) {
    return deliveryDate
  }

  if (hasValue(order, ['extension_attributes', 'amdeliverydate_date'])) {
    deliveryDate = order.extension_attributes.amdeliverydate_date
  }

  return deliveryDate
}

export const formatProductName = (product) => {
  let specialProductName = ''
  const volumeString = prepareVariant(product.bottle_volume)
  const alcoholType = product.bq_4740 || ''
  const productName = product.name
  specialProductName = `${alcoholType} ${productName} ${volumeString}`

  return specialProductName.trim()
}
