export const SN_CATEGORY = 'category'
export const CATEGORY_REMOVE_AVAILABLE_FILTERS = `${SN_CATEGORY}/REMOVE_AVAILABLE_FILTERS`
export const CATEGORY_UPDATE_CHOSEN_FILTER = `${SN_CATEGORY}/UPDATE_CHOSEN_FILTER`
export const CATEGORY_ADD_HEADER_FILTER = `${SN_CATEGORY}/ADD_HEADER_FILTER`
export const CATEGORY_ADD_HEADER_BEST_PRODUCT = `${SN_CATEGORY}/ADD_HEADER_BEST_PRODUCT`
export const CATEGORY_REMOVE_HEADER_FILTERS = `${SN_CATEGORY}/REMOVE_HEADER_FILTERS`
export const CATEGORY_SET_SUBCATEGORY_FOR_POPUP = `${SN_CATEGORY}/SET_SUBCATEGORY_FOR_POPUP`
export const CATEGORY_SET_GENERAL_AGG_OF_CATEGORY = `${SN_CATEGORY}/SET_GENERAL_AGG_OF_CATEGORY`
export const CATEGORY_REMOVE_GENERAL_AGG_OF_CATEGORY = `${SN_CATEGORY}/REMOVE_GENERAL_AGG_OF_CATEGORY`
export const CATEGORY_ADD_CATEGORY_AVAILABLE_FILTER = `${SN_CATEGORY}/ADD_CATEGORY_AVAILABLE_FILTER`
export const UPD_SHOW_REVIEWS_BTN = `${SN_CATEGORY}/UPD_SHOW_REVIEWS_BTN`
