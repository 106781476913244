<template>
  <div>
    <div v-if="productsWithBanners && productsWithBanners.length" class="product-listing m0 flex flex-wrap middle-xs w-100">
      <template v-for="(product, index) in productsWithBanners">
        <div ref="product"
             :key="product.banner_id ? parseInt(product.banner_id) + index : product.id"
             class="border-box col-xs-6 col-md-3 product-item"
        >
          <product-tile v-if="!product.banner_id"
                        :product="product"
          />
          <product-banner
            v-else
            :banner="product"
            class="product-banner"
          />
        </div>
      </template>
      <div class="float-clear w-100" />
    </div>
    <div v-else class="fs22 empty-category-text">
      {{ emptyCategoryText }}
    </div>
  </div>
</template>

<script>
import ProductTile from 'theme/components/core/ProductTile'
import ProductBanner from 'theme/components/theme/blocks/Collection/ProductBanner'
import Slider from 'src/modules/kt-storefront-module-slider/components/Slider'

let isFirstPartMiddleSlider = false
export default {
  name: 'ProductListing',
  components: {
    ProductBanner,
    ProductTile
  },
  mixins: [Slider],
  props: {
    products: {
      type: null,
      required: true
    },
    sameSizeProducts: {
      type: Boolean,
      required: false,
      default: false
    },
    sliderIdentifier: {
      type: String,
      default: '',
      required: false
    }
  },
  computed: {
    emptyCategoryText () {
      const filters = Object.keys(this.$store.state.category.filters.chosen)
      return filters.length ? 'Товаров по вашему запросу не найдено' : 'Товары в данной категории отсутствуют'
    },
    productsWithBanners () {
      const product = Array.isArray(this.products) ? [...this.products] : []
      const banners = Array.isArray(this.banners) ? [...this.banners] : []
      if (Array.isArray(banners) && banners.length && Array.isArray(product) && product.length) {
        let setCount = false
        let totalCount = 7 // start with
        let resultProductsWithBanners = []
        const generalLength = product.length + banners.length
        for (let x = 0; x < generalLength; x++) {
          if (x === totalCount && banners.length) {
            resultProductsWithBanners.push(banners.shift())
            setCount = !setCount
            totalCount += setCount ? 5 : 11 // next choice step
          } else {
            resultProductsWithBanners.push(product.shift())
          }
        }
        return resultProductsWithBanners.filter(v => !!v)
      } else {
        return product
      }
    }
  },
  methods: {
    getIsFirstPartMiddleSlider () {
      isFirstPartMiddleSlider = !isFirstPartMiddleSlider
      return isFirstPartMiddleSlider
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/_grid';
  .product-listing {
    font-size: 0;
  }
  .product-item {
    width: 100%;
    padding: 5px;
    min-height: 240px;
    max-height: 455px;
    position: relative;
    & > img {
      width: 100%;
      height: 100%;
    }
    & /deep/ .product {
      padding-bottom: 0;
      &:hover {
        padding-bottom: 40px;
      }
      @media #{$media-xs} {
        &:hover {
          padding-bottom: 0;
        }
      }
    }
  }
  .same-product-item {
    width: 25%;
    display: inline-block;
    overflow: hidden;
    min-height: 240px;
    position: relative;
    vertical-align: top;
    & > img {
      width: 100%;
      height: 100%;
    }
    & /deep/ .product {
      position: absolute;
      top: 2px;
      right: 2px;
      bottom: 2px;
      left: 2px;
    }
  }
  .float-clear {
    clear: both;
    height: 0;
  }
  @media #{$media-xs} {
    .same-product-item {
      width: 50%;
    }
    .product-item {
      width: 50%;
      display: inline-block;
      overflow: hidden;
      &:nth-child(10n+3) {
        width: 100%;
        float: none;
      }
      &:nth-child(10n+6) {
        width: 100%;
        float: none;
      }
    }
  }
</style>
