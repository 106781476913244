var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "custom-option mb15" },
    [
      _c("h4", [_vm._v(" " + _vm._s(_vm.option.title) + " ")]),
      _vm._v(" "),
      _vm._l(_vm.option.product_links, function(opval) {
        return _c("div", { key: opval.id, staticClass: "m5 relative" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.productOptionId,
                expression: "productOptionId"
              }
            ],
            staticClass: "m0 no-outline",
            attrs: {
              id: _vm.bundleOptionName + opval.id,
              name: _vm.bundleOptionName + opval.id,
              type: "radio",
              focus: ""
            },
            domProps: {
              value: opval.id,
              checked: _vm._q(_vm.productOptionId, opval.id)
            },
            on: {
              change: function($event) {
                _vm.productOptionId = opval.id
              }
            }
          }),
          _vm._v(" "),
          opval.product
            ? _c("label", {
                staticClass: "pl10 lh20 h4 pointer",
                attrs: { for: _vm.bundleOptionName + opval.id },
                domProps: { innerHTML: _vm._s(opval.product.name) }
              })
            : _vm._e()
        ])
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "label",
          { staticClass: "qty-label flex", attrs: { for: _vm.quantityName } },
          [_vm._v(_vm._s(_vm.$t("Quantity")))]
        ),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.quantity,
              expression: "quantity"
            }
          ],
          staticClass:
            "m0 no-outline qty-input py10 brdr-cl-primary bg-cl-transparent h4",
          attrs: {
            id: _vm.quantityName,
            name: _vm.quantityName,
            type: "number",
            min: "0",
            focus: ""
          },
          domProps: { value: _vm.quantity },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.quantity = $event.target.value
            }
          }
        })
      ]),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("span", { staticClass: "error" }, [
            _vm._v(_vm._s(_vm.errorMessage))
          ])
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }