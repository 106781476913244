import { ActionTree } from 'vuex'
import UserConfirmState from '../types/UserConfirmState'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'

const actions: ActionTree<UserConfirmState, RootState> = {
  confirmationCode (context, { customerHash }) {
    return new Promise((resolve, reject) => {
      if (customerHash) {
        return fetch(rootStore.state.config.confirmationCode.urlGet, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ customerHash })
        }).then(resp => resp.json()).then((resp) => {
          resolve(resp)
        }).catch(err => {
          console.error(err)
          reject(err)
        })
      }
    })
  },
  confirmationCodeApprove (context, data) {
    const { regCode, customerHash } = data
    return new Promise((resolve, reject) => {
      if (regCode && customerHash) {
        return fetch(rootStore.state.config.confirmationCode.urlApprove, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(data)
        }).then(resp => resp.json()).then((resp) => {
          resolve(resp)
        }).catch(err => {
          console.error(err)
          reject(err)
        })
      }
    })
  },
  sendConfirmationEmail (context, email) {
    return new Promise((resolve, reject) => {
      if (email) {
        return fetch(rootStore.state.config.confirmationCode.emailConfirm, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({email})
        }).then(resp => resp.json()).then((resp) => {
          resolve(resp)
        }).catch(err => {
          console.error(err)
          reject(err)
        })
      }
    })
  }
}

export default actions
