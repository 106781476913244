export default function defaultConfig () {
  return [
    {
      'banner_position': 1,
      'banner_name': 'BigBanner',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 2,
      'banner_name': 'PopularBrands',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 3,
      'banner_name': 'SellBanners',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 4,
      'banner_name': 'KindOfWhiskey',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 5,
      'banner_name': 'BrandsLogo',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 7,
      'banner_name': 'BottleViewBanner',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 8,
      'banner_name': 'BestSellers',
      'banner_description': '',
      'banner_visible': 1
    },
    {
      'banner_position': 9,
      'banner_name': 'SingleBigBanner',
      'banner_description': '',
      'banner_visible': 1
    }
  ]
}
