<template>
  <div class="w-100 h-100">
    <template>
      <pre>{{ banner.image }}</pre>
      <div v-lazy:background-image="getThumbnail(`/${banner.image}`, 325, 478)"
           :style="[{color: getTextColor}]"
           class="banner-wrapper w-100 h-100 border-box"
      >
        <div class="content-wrapper col-xs-12 flex between-xs">
          <div class="title">
            <div v-if="banner.title"
                 :class="{'big-text': !showSubtitle}"
                 class="w-100 banner-title bebas lh55 fs36 ls3 uppercase mb20"
            >
              {{ banner.title }}
            </div>
            <div v-if="showSubtitle" class="w-100 banner-subtitle serif lh22 fs14 pb26">
              {{ banner.subtitle }}
            </div>
          </div>
          <template v-if="banner.button_text">
            <navigation-link :url="getUrl(banner, 'click_url')"
                             :link="getUrl(banner, 'link')"
                             :style="{color: banner.text_color, borderColor: banner.btn_color}"
                             class="banner-button border-box weight-600 fs12 lh14 flex center-xs middle-xs ls4 uppercase pointer"
            >
              {{ banner.button_text }}
            </navigation-link>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import NavigationLink from 'theme/components/theme/blocks/Brands/NavigationLink'

export default {
  name: 'ProductBanner',
  components: {
    NavigationLink
  },
  props: {
    banner: {
      type: null,
      required: true
    }
  },
  computed: {
    showSubtitle () {
      return this.banner.subtitle
    },
    getTextColor () {
      if (this.banner.text_color) {
        return this.banner.text_color
      } else {
        return this.banner.bg_image ? '#fff' : '#000'
      }
    }
  },
  methods: {
    getUrl (banner, type) {
      return banner[`${type}`] ? banner[`${type}`] : ''
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/_grid';
  .banner-button {
    width: 100%;
    background: transparent;
    height: 50px;
    margin: 0 auto;
    border: 1.5px solid;
  }
  .product-banner {
    height: 479px;
  }
  .banner-wrapper {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 75px 35px;
    .content-wrapper {
      height: 100%;
      flex-direction: column;
    }
    .banner-subtitle {
      letter-spacing: 0.5px;
    }
  }
  .title {
    max-width: 400px;
  }
  @media #{$media-xlx} {
    .banner-title {
      line-height: 34px;
      font-size: 26px;
    }
    .banner-wrapper {
      .banner-title {
        letter-spacing: 1px;
        font-size: 28px;
      }
    }
  }
  @media #{$media-to-sm} {
    .product-banner {
      height: 376px;
    }
    .banner-wrapper {
      .banner-title {
        font-size: 19px;
        line-height: 28px;
        margin-bottom: 20px;
      }
    }
  }
  @media #{$media-mds} {
    .product-banner {
      height: 351px;
    }
    .banner-wrapper {
      padding: 45px 10px;
    }
  }
  @media #{$media-xs} {
    .product-banner {
      height: 299px;
    }
    .banner-wrapper {
      padding: 35px 20px;
      .banner-title {
        font-size: 27px;
        line-height: 34px;
      }
    }
  }
  @media #{$media-to-msm }{
    .banner-wrapper {
      padding: 20px;
      .banner-title {
        font-size: 27px;
        line-height: 30px;
        margin-bottom: 5px;
      }
      .big-text {
        font-size: 30px;
        line-height: 34px;
      }
      .banner-subtitle {
        padding-bottom: 5px;
        line-height: 22px;
      }
      .banner-button {
        letter-spacing: 2px;
      }
    }
  }
  @media #{$media-to-xxs} {
    .banner-wrapper {
      .banner-title {
        font-size: 21px;
        line-height: 24px;
      }
      .big-text {
        font-size: 30px;
        line-height: 34px;
      }
      .banner-subtitle {
        line-height: 19px;
      }
      .banner-button {
        letter-spacing: 1px;
        height: 40px;
      }
    }
  }
</style>
