import * as types from './mutation-types'
import LandingPagesState from '../types/LandingPagesState'
import { MutationTree } from 'vuex'

const mutations: MutationTree<LandingPagesState> = {
  [types.LANDING_PAGES_SET] (state, landingPagesData) {
    state.data = landingPagesData
  }
}
export default mutations
