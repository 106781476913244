import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import builder from 'bodybuilder'
import defaultConfig from './helper/defaultConfig.js'

const actions = {
  async getConfigForHomePage (context) {
    let query = builder().query('terms', 'path.keyword', ['banner_section/banner_group/banner_config']).build()
    await quickSearchByQuery({ query, entityType: 'core_config', size: 1 }).then(async (resp) => {
      const configForHomePage = resp && Array.isArray(resp.items) && resp.items.length && Array.isArray(resp.items[0].config) ? resp.items[0].config : await defaultConfig()
      context.commit('SET_CONFIG_FOR_HOME_PAGE', configForHomePage)
    })
  }
}

export default actions
