import { MutationTree } from 'vuex'
import * as localTypes from './mutation-types'
import CategoryState from '../../types/CategoryState'
import Vue from 'vue'

const mutations: MutationTree<CategoryState> = {
  [localTypes.CATEGORY_REMOVE_AVAILABLE_FILTERS] (state) {
    state.filters.available = {}
  },
  [localTypes.CATEGORY_UPDATE_CHOSEN_FILTER] (state, filters) {
    state.filters.chosen = {
      ...filters
    }
  },
  [localTypes.CATEGORY_ADD_HEADER_FILTER] (state, {slug, key, options = []}) {
    if (key && slug) Vue.set(state.header_filters, slug, {...state.header_filters[slug], [key]: options})
  },
  [localTypes.CATEGORY_REMOVE_HEADER_FILTERS] (state) {
    state.header_filters = {}
  },
  [localTypes.CATEGORY_ADD_HEADER_BEST_PRODUCT] (state, bestProducts) {
    state.header_best_product = bestProducts
  },
  [localTypes.CATEGORY_SET_SUBCATEGORY_FOR_POPUP] (state, subcategory) {
    state.subcategoryForPopup = subcategory
  },
  [localTypes.CATEGORY_SET_GENERAL_AGG_OF_CATEGORY] (state, agg) {
    state.generalAggOfCategory = Object.assign(state.generalAggOfCategory, agg)
  },
  [localTypes.CATEGORY_REMOVE_GENERAL_AGG_OF_CATEGORY] (state) {
    state.generalAggOfCategory = {}
  },
  [localTypes.CATEGORY_ADD_CATEGORY_AVAILABLE_FILTER] (state, {key, value}) {
    if (!key) return
    if (!Array.isArray(state.filters.available[key])) Vue.set(state.filters.available, key, [])
    let index = state.filters.available[key].findIndex(availableValue => availableValue.id === value.id)
    if (index !== -1) {
      state.filters.available[key][index]['count'] = value.count
    } else {
      state.filters.available[key].push(value)
    }
  },
  [localTypes.UPD_SHOW_REVIEWS_BTN] (state, isReviewsBtnShown) {
    state.isReviewsBtnShown = isReviewsBtnShown
  }
}

export default mutations
