import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import SliderModuleState from '../types/sliderModuleState'

const mutations: MutationTree<SliderModuleState> = {
  /**
   * Store Sliders by identifier in state
   * @param {} state
   * @param {Array} slider
   */
  [types.ADD_SLIDERS] (state, sliders) {
    state.items = sliders || []
  },
  [types.ADD_SLIDER] (state, slider) {
    slider.forEach(banner => {
      const record = state.items.find(c => c.id === banner.id)
      if (!record) {
        state.items.push(banner)
      } else {
        state.items.forEach((item, index) => {
          if (item.id === banner.id) {
            state.items[index] = item
          }
        })
      }
    })
  }
}

export default mutations
