import { extendModule, VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { isServer } from '@vue-storefront/core/helpers'

import { Catalog } from '@vue-storefront/core/modules/catalog'
import { Cart } from '@vue-storefront/core/modules/cart'
import { Checkout } from '@vue-storefront/core/modules/checkout'
import { Compare } from '@vue-storefront/core/modules/compare'
import { Review } from '@vue-storefront/core/modules/review'
import { Mailer } from '@vue-storefront/core/modules/mailer'
// KT CHANGES START
import { Mailchimp } from './mailchimp'
// KT CHANGES END
import { Notification } from '@vue-storefront/core/modules/notification'
import { RecentlyViewed } from '@vue-storefront/core/modules/recently-viewed'
// KT CHANGES START
// import { Cms } from '@vue-storefront/core/modules/cms'
// import { Order } from '@vue-storefront/core/modules/order'
import { Url } from '@vue-storefront/core/modules/url'
// KT CHANGES END
import { Homepage } from './homepage'
import { Claims } from './claims'
import { Ui } from './ui-store'
import { GoogleAnalytics } from './google-analytics'
import { AmpRenderer } from './amp-renderer'
import { PaymentBackendMethods } from './payment-backend-methods'
import { RawOutputExample } from './raw-output-example'
import { Magento2CMS } from './magento-2-cms'
// KT CHANGES START
import { KtStorefrontModuleCatalog } from './kt-storefront-module-catalog'
import { KtStorefrontModuleCms } from './kt-storefront-module-cms'
import { KtStorefrontModuleReview } from './kt-storefront-module-review'
import { KtGeoLocation } from './kt-geo-location'
import { KtStorefrontModuleSlider } from './kt-storefront-module-slider'
import { KtStorefrontModuleCart } from './kt-storefront-module-cart'
import { KtStorefrontModuleOrder } from './kt-storefront-module-order'
import { KtStorefrontModuleUi } from './kt-storefront-module-ui'
import { User } from '@vue-storefront/core/modules/user'
import { KtStorefrontModuleUser } from './kt-storefront-module-user'
import { ktStorefrontModuleMessage } from './kt-storefront-module-message'
import { KtStorefrontModuleNews } from './kt-storefront-module-news'
import { KtStorefrontUserConfirm } from './kt-storefront-module-user-confirm'
import { Wishlist } from '@vue-storefront/core/modules/wishlist'
import { KtStorefrontModuleWishlist } from './kt-storefront-module-wishlist'
import { KtStorefrontModuleMeta } from './kt-storefront-meta'
import { Subscribtion } from './kt-subscribtion'
import { KtClearCache } from './kt-vsf-clear-cache'
import { KtGtm } from './kt-GTM'
import { KtMindbox } from './kt-mindBox'
import { KtStorefrontComment } from './kt-storefront-module-comment'
import { KtStorefrontModuleCoreConfig } from './kt-storefront-module-core-config'
import { OnpeakStorefrontModuleLandingPages } from './onpeak-storefront-landing-pages'
// KT CHANGES END

// KT CHANGES START
extendModule(KtStorefrontModuleCatalog)
extendModule(KtStorefrontModuleCms)
extendModule(KtStorefrontModuleCart)
extendModule(KtStorefrontModuleOrder)
extendModule(KtStorefrontModuleUi)
extendModule(KtStorefrontModuleUser)
extendModule(KtStorefrontModuleWishlist)
extendModule(KtStorefrontModuleReview)
// KT CHANGES END

/* KT CHANGES REGISTR MODULES
User,
KtGeoLocation,
KtStorefrontModuleSlider,
ktStorefrontModuleMessage,
KtStorefrontModuleNews,
KtStorefrontUserConfirm
Wishlist
KtStorefrontModuleWishlist,
Url
*/
export const registerModules: VueStorefrontModule[] = [
  Checkout,
  Catalog,
  Cart,
  User,
  Wishlist,
  Compare,
  Review,
  Mailer,
  Mailchimp,
  Notification,
  Ui,
  RecentlyViewed,
  Homepage,
  Claims,
  Magento2CMS,
  GoogleAnalytics,
  PaymentBackendMethods,
  RawOutputExample,
  KtGeoLocation,
  KtStorefrontModuleSlider,
  ktStorefrontModuleMessage,
  KtStorefrontModuleNews,
  KtStorefrontUserConfirm,
  KtStorefrontModuleMeta,
  Url,
  Subscribtion,
  KtClearCache,
  KtStorefrontComment,
  KtGtm,
  KtMindbox,
  KtStorefrontModuleCoreConfig,
  OnpeakStorefrontModuleLandingPages
]
// KT CHANGES START
if (!isServer) { // extensions that are not required in the SSR mode
  registerModules.push(AmpRenderer)
}
// KT CHANGES END
