export default {
  name: 'Slider',
  props: {
    sliderId: {
      type: Number,
      default: null,
      required: false
    },
    sliderIdentifier: {
      type: String,
      default: null,
      required: false
    }
  },
  async serverPrefetch () {
    let queryKey = ''
    let queryValue = ''
    if (this.sliderId) {
      queryKey = 'slider_id'
      queryValue = this.sliderId
    } else if (this.sliderIdentifier) {
      queryKey = 'slider_identifier.keyword'
      queryValue = this.sliderIdentifier
    }
    if (queryKey && queryValue) {
      await this.$store.dispatch('ktSliders/single', {
        filterField: queryKey,
        filterValues: queryValue
      })
    }
  },
  async created () {
    let queryKey = ''
    let queryValue = ''
    if (this.sliderId) {
      queryKey = 'slider_id'
      queryValue = this.sliderId
    } else if (this.sliderIdentifier) {
      queryKey = 'slider_identifier.keyword'
      queryValue = this.sliderIdentifier
    }
    if (queryKey && queryValue) {
      if (this.banners) {
        await this.$store.dispatch('ktSliders/single', {
          filterField: queryKey,
          filterValues: queryValue
        })
      }
    }
  },
  computed: {
    banners () {
      let gettersName, value, slider
      if (this.sliderId) {
        gettersName = `ktSliders/sliderById`
        value = this.sliderId
      } else if (this.sliderIdentifier) {
        gettersName = `ktSliders/sliderByIdentifier`
        value = this.sliderIdentifier
      }
      if (gettersName && value) {
        slider = this.$store.getters[gettersName](value)
      }
      if (slider) {
        slider = slider.filter(slide => slide.status === '1')
      }

      return slider || []
    }
  }
}
