import { findFieldByCollectionLink } from 'theme/helpers/index'

export default {
  computed: {
    productRouterLink () {
      const prefix = this.getRouteLinkPrefix
      if (this.avaibleCollectionPageLink) {
        return this.localizedRoute({
          name: 'collection-product',
          params: {
            collection: this.avaibleCollectionPageLink,
            urlKey: this.sampleProduct ? this.sampleProduct.url_key : this.product.url_key
          }
        })
      }
      let productUrlKey = this.product ? this.product.url_key : ''
      if (this.sampleProduct) {
        productUrlKey = this.sampleProduct.url_key
        const isGiftSimpleProduct = parseInt(this.sampleProduct.is_gift_product)
        if (isGiftSimpleProduct) {
          productUrlKey = productUrlKey.slice(0, -2) // убираем -1 из урла, для перехода на оригинал продукта с копии
        }
      }
      if (prefix && this.isVipCategory && this.product) {
        productUrlKey = this.product.url_key
      }
      return this.localizedRoute({
        name: `${prefix}product`,
        params: {
          ...this.isSpecialEvent ? { eventCode: this.$route.params.eventCode } : {},
          urlKey: productUrlKey
        }
      })
    },
    getRouteLinkPrefix () {
      if (this.isSpecialEvent) {
        return 'special-'
      }
      if (this.isVipCategory) {
        return 'vip-'
      }
      return ''
    },
    isSpecialEvent () {
      return this.$route.name && this.$route.name.includes('special-')
    },
    isVipCategory () {
      return this.$route.name === 'vip-category'
    },
    avaibleCollectionPageLink () {
      return findFieldByCollectionLink(this.$store.state.config.collectionKeys, this.$route.name, 'collection-link')
    },
    sampleProduct () {
      if (this.$route.query.bottle_volume_options) {
        let productSelected = null
        let bottleVolumesSelectedInFilters = []
        let bottleVolumeOptions = this.$route.query.bottle_volume_options
        // может прийти строка если параметр один
        if (Array.isArray(bottleVolumeOptions)) {
          bottleVolumesSelectedInFilters = bottleVolumeOptions
        } else {
          bottleVolumesSelectedInFilters.push(bottleVolumeOptions)
        }

        for (let i = bottleVolumesSelectedInFilters.length - 1; i >= 0; i--) {
          if (this.product.type_id === 'simple') {
            productSelected = this.product
          } else {
            productSelected = this.product.configurable_children.find(product => parseInt(product.bottle_volume) === parseInt(bottleVolumesSelectedInFilters[i]))
          }
          if (productSelected) {
            return productSelected
          }
        }
        return productSelected || this.defaultBottle
      } else {
        return this.defaultBottle || this.product
      }
    },
    defaultBottle () {
      let defaultProduct = null
      if (!this.$route.query.bottle_volume_options && this.product && this.product.configurable_children) {
        let defaultBottleVolumes = this.$store.state.config.volumesProduct
        for (let i = 0; i < defaultBottleVolumes.length; i++) {
          defaultProduct = this.product.configurable_children.find(product => parseInt(product.bottle_volume) === defaultBottleVolumes[i])
          if (defaultProduct) {
            return defaultProduct
          }
        }
      }
      return defaultProduct
    }
  }
}
