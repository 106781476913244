import * as types from './mutation-types'
import { ActionTree } from 'vuex'
import MetaState from '../types/MetaState'
import RootState from 'core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import fetch from 'isomorphic-fetch'

const actions: ActionTree<MetaState, RootState> = {
  loadMetaData (context, route) {
    let url = rootStore.state.config.meta.endpoint
    if (!url.startsWith('http') && typeof window === 'undefined') {
      url = `${rootStore.state.config.api.internalHost}${url}`
    }
    return fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ route })
    })
      .then(resp => resp.json())
      .then(metadata => {
        const meta = metadata.result && metadata.result.id ? metadata.result : null
        context.commit(types.META_SET, meta)
      })
  }
}

export default actions
