import Head from 'theme/resource/head'
export default {
  computed: {
    metaData () {
      return this.$store.state.meta.data
    },
    metaTitle () {
      return this.metaData && this.metaData.title ? this.metaData.title : ''
    },
    metaDescription () {
      return this.metaData && this.metaData.description ? this.metaData.description : ''
    },
    metaH1 () {
      return this.metaData && this.metaData.h1_title ? this.metaData.h1_title : ''
    },
    metaCanonical () {
      return this.metaData && this.metaData.canonical ? this.metaData.canonical : ''
    },
    noindexNofollow () {
      return this.metaData && this.metaData.noindex_nofollow ? this.metaData.noindex_nofollow : ''
    },
    underContentPage () {
      return this.metaData && this.metaData.under_content_page ? this.metaData.under_content_page : ''
    }
  },
  methods: {
    setMetaDescription () {
      const metaDescriptionTag = Head.meta.find(tag => tag.name === 'description')
      if (this.metaDescription) {
        metaDescriptionTag.content = this.metaDescription
      } else {
        metaDescriptionTag.content = 'Maxxium Russia – российское подразделение международного альянса двух ведущих компаний-производителей крепкого алкоголя Beam Suntory Inc. и The Edrington.'
      }
    },
    setMetaTitle () {
      if (this.metaTitle) {
        Head.title = this.metaTitle
      } else {
        Head.title = 'Официальный сайт'
      }
    }
  },
  metaInfo () {
    this.setMetaDescription()
    this.setMetaTitle()
    return Head
  }
}
