import { module } from './store'
import { plugin } from '@vue-storefront/core/modules/wishlist/store/plugin'
import { VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import { initCacheStorage } from '@vue-storefront/core/helpers/initCacheStorage'

export const KEY = 'wishlist'
export const cacheStorage = initCacheStorage(KEY)

const moduleConfig: VueStorefrontModuleConfig = {
  key: KEY,
  store: { modules: [{ key: KEY, module }], plugin }
}

export const KtStorefrontModuleWishlist = moduleConfig
