import mutations from './mutations'
import getters from './getters'
import { mindbox } from './mindbox'

const state = {
  authbar: false
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  modules: {
    mindbox
  }
}
