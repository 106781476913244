const mutations = {
  addServerMessage (state, message) {
    state.serverMessage = message
  },
  removeServerMessage (state) {
    state.serverMessage = ''
  },
  addMessage (state, message) {
    state.message = message
  },
  removeMessage (state) {
    state.message = ''
  },
  toggleNotificationHidden (state, isHidden) {
    state.isNotificationHidden = isHidden
  }
}

export default mutations
