import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import UserState from '../types/UserState'

const mutations: MutationTree<UserState> = {
  [types.USER_TOKEN_CHANGED] (state, payload) {
    state.token = payload.newToken
    if (payload.meta && payload.meta.refreshToken) {
      state.refreshToken = payload.meta.refreshToken // store the refresh token
      console.log('Refresh token is set to', state.refreshToken)
    }
  },
  [types.USER_ORDERS_HISTORY_LOADED] (state, ordersHistory) {
    state.orders_history = ordersHistory
  },
  [types.USER_ONE_ORDER_LOADED] (state, oneOrder) {
    state.order.current = oneOrder.items.length > 0 ? oneOrder.items[0] : []
  },
  [types.USER_SET_HASH] (state, value) {
    state.hash = value
  },
  [types.USER_SET_PHONE] (state, phone) {
    state.phone = phone
  },
  [types.USER_AGE_CONFIRM] (state) {
    state.ageConfirm = true
  },
  [types.USER_SET_CONFIRM_CODE] (state, code) {
    state.confirmCode = code
  }
}

export default mutations
