import { GetterTree } from 'vuex'
import SliderModuleState from '../types/sliderModuleState'
import RootState from '@vue-storefront/core/types/RootState'

const getters: GetterTree<SliderModuleState, RootState> = {
  sliderByIdentifier: (state) => (identifier) => {
    return state.items.filter(item => item['slider_identifier'] === identifier)
  },
  sliderById: (state) => (id) => {
    return state.items.filter(item => item['slider_id'] === id)
  }
}

export default getters
