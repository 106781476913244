import { setupMultistoreRoutes } from '@vue-storefront/core/lib/multistore'
import App from './App.vue'
import routes from './router'
import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'
import VueTheMask from 'vue-the-mask'
import VueCookies from 'vue-cookies'
import StarRating from 'vue-star-rating'
import SocialSharing from 'vue-social-sharing'
import '@vue-storefront/core/lib/passive-listeners'
import { RouterManager } from '@vue-storefront/core/lib/router-manager'
import { once } from '@vue-storefront/core/helpers'
import vClickOutside from 'v-click-outside'

once('__VUE_EXTEND_DROPPOINT_VPB__', () => {
  Vue.use(VueProgressBar)
  Vue.use(VueTheMask)
  Vue.use(VueCookies)
  Vue.component('star-rating', StarRating)
  Vue.use(SocialSharing)
  Vue.use(vClickOutside)
})

if (process.browser) {
  const VueAwesomeSwiper = require('vue-awesome-swiper/dist/ssr')
  Vue.use(VueAwesomeSwiper)
}

const themeEntry = App
function initTheme (app, router, store, config, ssrContext) {
  if (ssrContext && ssrContext.hasOwnProperty('server')) {
    store.dispatch('geoLocation/setGeoInformation', ssrContext.server.request.headers)
  }
  // if youre' runing multistore setup this is copying the routed above adding the 'storeCode' prefix to the urls and the names of the routes
  // You can do it on your own and then be able to customize the components used for example for German storeView checkout
  // To do so please execlude the desired storeView from the config.storeViews.mapStoreUrlsFor and map the urls by Your own like:
  // { name: 'de-checkout', path: '/checkout', component: CheckoutCustomized },
  setupMultistoreRoutes(config, router, routes)
  RouterManager.addRoutes(routes, router)
}

export {
  themeEntry,
  initTheme
}
