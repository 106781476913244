import { isClient, checkForRequiredFields, prepareProducts, formatProductName } from '../helpers'

export const actions = {
  userVisitedPage (context, userId) {
    if (!userId) {
      throw new Error('User id is required field')
    }
    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ userID: userId })
    }
  },
  /**
   * Any user visited product card
   * @param {Object} product product info
   */
  visitedProductPage (context, product) {
    const requiredFields = ['name', 'id', 'price']
    if (!checkForRequiredFields(requiredFields, product)) {
      throw new Error('Missing required field')
    }

    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommerceDetail',
        ecommerce: {
          detail: {
            products: prepareProducts([product], false)
          }
        }
      })
    }
  },
  /**
   * Add product to cart
   * @param {Object} product product info
   */
  addProductToCart (context, product) {
    const requiredFields = ['name', 'id', 'qty']
    if (!checkForRequiredFields(requiredFields, product)) {
      throw new Error('Missing required field')
    }

    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommerceAdd',
        ecommerce: {
          add: {
            products: prepareProducts([product])
          }
        }
      })
    }
  },
  visitedCartPage (context, products) {
    const requiredFields = ['name', 'id', 'qty']
    for (let product of products) {
      if (!checkForRequiredFields(requiredFields, product)) {
        throw new Error('Missing required field')
      }
    }

    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommerceCart',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: prepareProducts(products)
          }
        }
      })
    }
  },
  visitedCheckoutPage (context, { step = 1, products }) {
    const requiredFields = ['name', 'id', 'qty']
    for (let product of products) {
      if (!checkForRequiredFields(requiredFields, product)) {
        throw new Error('Missing required field')
      }
    }

    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommerceCheckout',
        ecommerce: {
          checkout: {
            actionField: {
              step
            },
            products: prepareProducts(products)
          }
        }
      })
    }
  },
  transaction (context, order) {
    const orderRequiredFields = ['entity_id', 'base_grand_total', 'shipping_amount', 'items', 'payment']
    if (!checkForRequiredFields(orderRequiredFields, order)) {
      throw new Error('Missing required field')
    }
    const requiredFields = ['name', 'id', 'qty']
    for (let product of order.items) {
      if (!checkForRequiredFields(requiredFields, product)) {
        throw new Error('Missing required field')
      }
    }

    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommercePurchase',
        cd1: order.payment.method || '',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.entity_id,
              revenue: order.base_grand_total
            },
            products: prepareProducts(order.items)
          }
        }
      })
    }
  },
  transactionForProduct (context, { product, event }) {
    const productRequiredFields = ['name', 'id', 'qty']
    for (let item of product) {
      if (!checkForRequiredFields(productRequiredFields, item)) {
        throw new Error('Missing required field')
      }
    }
    product.tranzId = event + Date.now()
    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'ecommercePurchase',
        cd1: event || '',
        ecommerce: {
          purchase: {
            actionField: {
              id: product.tranzId,
              revenue: product.price
            },
            products: prepareProducts([product])
          }
        }
      })
    }
  },
  additionalEvents (context, { event, product }) {
    if (typeof product !== 'object' || product == null) {
      throw new Error('Wrong type incoming data')
    }
    const fullProductName = formatProductName(product)
    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event, fullProductName })
    }
  },
  addEvent (context, { event }) {
    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event })
    }
  },
  searchEvent (context, search) {
    if (isClient) {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'event': 'search',
        'searchTerm': search
      })
    }
  }
}
