import config from 'config'
import { clearCache } from './cache'

const _getVersion = async () => {
  try {
    const version = await fetch(`${config.clearCache.version.endpoint}?timestmp=${Date.now()}`)
    return version
  } catch (error) {
    console.error('CLEAR CACHE: Error through recieve server version VSF - ', error)
  }
}

const checkVersion = async () => {
  const response = await _getVersion()
  let serverVersion = await response.json()
  if (serverVersion.code === 200) {
    serverVersion = serverVersion.result
    const clientVersion = localStorage.getItem(config.clearCache.version.key)
    console.log('CLEAR CACHE: Current server version VSF - ', serverVersion)
    console.log('CLEAR CACHE: Current client version VSF - ', clientVersion)

    if ((serverVersion && clientVersion && serverVersion.toString() !== clientVersion.toString()) || !clientVersion) {
      console.log('CLEAR CACHE: Server version does not match client version, start clear cache')
      clearCache()
    } else if (serverVersion && clientVersion && serverVersion.toString() === clientVersion.toString()) {
      console.log('CLEAR CACHE: The client has the current version of the site')
    }

    localStorage.setItem(config.clearCache.version.key, serverVersion)
  } else {
    console.log('CLEAR CACHE: the answer code does not 200')
  }
}

export {
  checkVersion
}
