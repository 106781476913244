import { cmsPageModule } from './store/page'
import { cmsBlockModule } from './store/block'
import { cmsHierarchyModule } from '@vue-storefront/core/modules/cms/store/hierarchy'
import { VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import { plugin } from '@vue-storefront/core/modules/cms/store/plugin'

export const KEY = 'cms'

const moduleConfig: VueStorefrontModuleConfig = {
  key: KEY,
  store: {
    modules: [
      { key: 'cmsPage', module: cmsPageModule },
      { key: 'cmsBlock', module: cmsBlockModule },
      { key: 'cmsHierarchy', module: cmsHierarchyModule }
    ],
    plugin
  }
}

export const KtStorefrontModuleCms = moduleConfig
