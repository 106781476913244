export const SN_USER = 'user'
export const USER_TOKEN_CHANGED = SN_USER + '/TOKEN_CHANGED'
export const USER_UPDATE_PREFERENCES = SN_USER + '/UPDATE_PREFERENCES'
export const USER_ORDERS_HISTORY_LOADED = SN_USER + '/ORDERS_HISTORY_LOADED'
export const USER_ONE_ORDER_LOADED = SN_USER + '/ORDER_HISTORY_LOADED'
export const USER_SET_HASH = SN_USER + '/SET_USER_HASH'
export const USER_SET_PHONE = SN_USER + '/SET_USER_PHONE'
export const USER_SET_CONFIRM_CODE = SN_USER + '/SET_CONFIRM_CODE'
export const USER_INFO_LOADED = SN_USER + '/INFO_LOADED'
export const USER_AGE_CONFIRM = SN_USER + '/AGE_CONFIRM'
