import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import OrderState from '../types/OrderState'

const mutations: MutationTree<OrderState> = {
  [types.SET_MINIMAL_ORDER] (state, minimalOrder) {
    state.minimalOrder = minimalOrder
  }
}

export default mutations
