import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import { processURLAddress } from '@vue-storefront/core/helpers'
import Vue from 'vue'
import i18n from '@vue-storefront/i18n'
import rootStore from '@vue-storefront/core/store'

const actions = {
  add (context, reviewData) {
    const review = {review: reviewData}
    Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Adding a review ...'))
    let url = rootStore.state.config.reviews.create_endpoint

    if (rootStore.state.config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(processURLAddress(url), {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(review)
    }).catch(e => {
      console.error(e)
    }).finally(() => {
      Vue.prototype.$bus.$emit('notification-progress-stop')
    })
  }
}

export default actions
