const getters = {
  productRating: state => {
    if (state.items.items) {
      const sumAllRating = state.items.items.reduce((acc, item) => {
        acc += item.rating
        return acc
      }, 0)
      const rating = (sumAllRating / state.items.items.length)
      if (rating && typeof rating === 'number') {
        return Number(rating.toFixed(1))
      } else {
        return Number(0)
      }
    }
  },
  amountReviews: state => {
    if (state.items.items) {
      return state.items.items.length
    }
    return 0
  }
}

export default getters
