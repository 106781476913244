var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "category" } }, [
    _c("header", { staticClass: "bg-cl-secondary py35 pl20" }, [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("breadcrumbs", {
            attrs: {
              routes: _vm.breadcrumbs.routes,
              "active-route": _vm.category.name
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "row middle-sm" }, [
            _c("h1", { staticClass: "col-sm-9 category-title mb10" }, [
              _vm._v("\n          " + _vm._s(_vm.category.name) + "\n        ")
            ])
          ])
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "container pb60" }, [
      _c("div", { staticClass: "row m0 pt15" }, [
        _c("p", { staticClass: "col-xs-12 hidden-md m0 px20 cl-secondary" }, [
          _vm._v("\n        " + _vm._s(_vm.productsCounter) + " items\n      ")
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-md-9 pt20 px10 border-box products-list block-center"
          },
          [
            _vm.isCategoryEmpty
              ? _c("div", { staticClass: "hidden-xs" }, [
                  _c("h4", { attrs: { "data-testid": "noProductsInfo" } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.$t("No products found!")) +
                        "\n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "Please change Your search criteria and try again. If still not finding anything relevant, please visit the Home page and try out some of our bestsellers!"
                        )
                      )
                    )
                  ])
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("product-listing", {
              attrs: { columns: "4", products: _vm.products }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }