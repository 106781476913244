import { Module } from 'vuex'
import actions from './actions'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../../types/CategoryState'
import mutations from './mutations'
import getters from './getters'

export const categoryModule: Module<CategoryState, RootState> = {
  namespaced: true,
  state: {
    list: [],
    current: {},
    filters: {
      available: {},
      chosen: {}
    },
    breadcrumbs: {
      routes: []
    },
    current_product_query: null,
    current_path: [],
    header_filters: {},
    header_best_product: [],
    subcategoryForPopup: {},
    generalAggOfCategory: {},
    isReviewsBtnShown: false
  },
  actions,
  mutations,
  getters
}
