<template>
  <div class="label-block relative">
    <div :style="{color: checkColorText}" class="w-100 text uppercase fs12 lh18 text-label bebas">
      {{ text }}
    </div>
    <svg width="109"
         height="31"
         viewBox="0 0 109 31"
         fill="none"
         xmlns="http://www.w3.org/2000/svg"
    >
      <path :fill="checkColorBg" d="M109 -6.67189e-06L102 15L109 31L0 31L-1.35505e-06 1.71661e-05L109 -6.67189e-06Z" />
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    bgColor: {
      type: String,
      required: false,
      default: 'black'
    },
    textColor: {
      type: String,
      required: false,
      default: 'white'
    },
    text: {
      type: String,
      required: false,
      default: ''
    }
  },
  computed: {
    checkColorText () {
      return this.textColor ? this.textColor : 'white'
    },
    checkColorBg () {
      return this.bgColor ? this.bgColor : 'black'
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~theme/css/variables/_grid';
  .text-label {
    letter-spacing: 1.5px;
  }
  .label-block {
    position: absolute;
    top: 10px;
    left: 0;
    z-index: 1;
    @media #{$media-xs} {
      width: 90px;
      height: 25px;
      svg {
        max-width: 100%;
        height: auto;
      }
    }
  }

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
</style>
