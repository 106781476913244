import { VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import store from './store'

const KEY = 'review'

const moduleConfig: VueStorefrontModuleConfig = {
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] }
}

export const KtStorefrontModuleReview = moduleConfig
