import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state: {
    ratingForModal: 5
  },
  mutations,
  getters,
  actions
}
