const actions = {
  setCity ({commit}, data) {
    commit('setCity', data)
  },
  setGeoInformation ({commit}, data) {
    commit('setGeoInformation', data)
  }
}

export default actions
