import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { adjustMultistoreApiUrl } from '@vue-storefront/core/lib/multistore'
import { ValidationError } from '@vue-storefront/core/store/lib/exceptions'
import i18n from '@vue-storefront/i18n'
import has from 'lodash/has'
import axios from 'axios'
import { Logger } from '@vue-storefront/core/lib/logger'
const Ajv = require('ajv')

const actions: ActionTree<UserState, RootState> = {
  me (context, { refresh = true, useCache = true }) {
    return new Promise((resolve) => {
      if (!context.state.token) {
        Logger.warn('No User token, user unauthorized', 'user')()
        return resolve(null)
      }
      const cache = Vue.prototype.$db.usersCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('current-user', (err, res) => {
          if (err) {
            console.error(err)
            return
          }
          if (res) {
            context.commit(types.USER_INFO_LOADED, res)
            context.dispatch('setUserGroup', res)
            Vue.prototype.$bus.$emit('user-after-loggedin', res)
            rootStore.dispatch('cart/authorize')

            resolve(res)
            resolvedFromCache = true
            console.log('Current user served from cache')
          }
        })
      }

      if (refresh) {
        TaskQueue.execute({ url: rootStore.state.config.users.me_endpoint,
          payload: { method: 'GET',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            }
          }
        })
          .then((resp) => {
            if (resp.resultCode === 200) {
              context.commit(types.USER_INFO_LOADED, resp.result) // this also stores the current user to localForage
              context.dispatch('setUserGroup', resp.result)
            }
            if (!resolvedFromCache && resp.resultCode === 200) {
              Vue.prototype.$bus.$emit('user-after-loggedin', resp.result)
              rootStore.dispatch('cart/authorize')
              resolve(resp)
            } else {
              resolve(null)
            }
            if (resp.result && !resp.result.id) {
              context.dispatch('logout', { silent: false })
              resolve(null)
            }
            return resp
          })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },
  getOrdersHistory (context, { refresh = true, useCache = true, pageSize = 10, currentPage = 1 }) {
    // TODO: Make it as an extension from users module
    return new Promise((resolve) => {
      if (!context.state.token) {
        console.debug('No User token, user unathorized')
        return resolve(null)
      }
      const cache = Vue.prototype.$db.ordersHistoryCollection
      let resolvedFromCache = false

      if (useCache === true) { // after login for example we shouldn't use cache to be sure we're loading currently logged in user
        cache.getItem('orders-history', (err, res) => {
          if (err) {
            console.error(err)
            return
          }

          if (res) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, res)
            Vue.prototype.$bus.$emit('user-after-loaded-orders', res)

            resolve(res)
            resolvedFromCache = true
            console.log('Current user order history served from cache')
          }
        })
      }

      if (refresh) {
        return TaskQueue.execute({ url: rootStore.state.config.users.history_endpoint,
          payload: { method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${rootStore.state.user.token}`
            },
            body: JSON.stringify({ currentPage: currentPage, pageSize: pageSize })
          }
        }).then((resp) => {
          if (resp.code === 200) {
            context.commit(types.USER_ORDERS_HISTORY_LOADED, resp.result) // this also stores the current user to localForage
            Vue.prototype.$bus.$emit('user-after-loaded-orders', resp.result)
          }
          if (!resolvedFromCache) {
            resolve(resp.code === 200 ? resp : null)
          }
          return resp
        })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },
  getOneOrder (context, { entityId = true }) {
    // TODO: Make it as an extension from users module
    return new Promise((resolve) => {
      context.commit(types.USER_ONE_ORDER_LOADED, {items: []})
      if (!context.state.token) {
        console.info('No User token, user unathorized')
        return resolve(null)
      }
      let resolvedFromCache = false

      if (entityId) {
        return TaskQueue.execute({ url: rootStore.state.config.users.one_order_endpoint,
          payload: { method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${rootStore.state.user.token}`
            },
            body: JSON.stringify({ entityId: entityId })
          }
        }).then((resp) => {
          if (resp.code === 200) {
            context.commit(types.USER_ONE_ORDER_LOADED, resp.result) // this also stores the current user to localForage
            Vue.prototype.$bus.$emit('user-order-loaded', resp.result)
          }
          if (!resolvedFromCache) {
            resolve(resp.code === 200 ? resp : null)
          }
          return resp
        })
      } else {
        if (!resolvedFromCache) {
          resolve(null)
        }
      }
    })
  },

  repeatOrder (context, { orderId = '' }) {
    return new Promise((resolve) => {
      if (orderId) {
        return TaskQueue.execute({ url: rootStore.state.config.users.repeat_order_endpoint,
          payload: { method: 'POST',
            mode: 'cors',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${rootStore.state.user.token}`
            },
            body: JSON.stringify({ orderId: orderId, quoteId: rootStore.state.cart.cartServerToken })
          }
        }).then((resp) => {
          resolve(resp)
        })
      }
    })
  },

  login (context, { username, password, verifyType }) {
    let url = rootStore.state.config.users.login_endpoint
    if (rootStore.state.config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(url, { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username: username,
        password: password,
        verifyEmailOrPhone: verifyType,
        uuid: rootStore.state.ui.mindbox.mindboxUUID })
    }).then(resp => { return resp.json() })
      .then(async (resp) => {
        if (resp.code === 200 && !resp.result.message) {
          rootStore.state.userTokenInvalidateLock = 0
          context.commit(types.USER_TOKEN_CHANGED, { newToken: resp.result, meta: resp.meta }) // TODO: handle the "Refresh-token" header
          await context.dispatch('me', { refresh: true, useCache: false })
        }
        return resp
      })
  },
  /**
   * Login user and return user profile and current token
   */
  register (context, { customer, password }) {
    let url = rootStore.state.config.users.create_endpoint
    if (rootStore.state.config.storeViews.multistore) {
      url = adjustMultistoreApiUrl(url)
    }
    return fetch(url, { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customer: customer, password: password, uuid: rootStore.state.ui.mindbox.mindboxUUID })
    }).then(resp => { return resp.json() })
      .then((resp) => {
        if (
          has(resp, ['result', 'extension_attributes', 'customer_hash']) &&
          resp.result.extension_attributes.customer_hash
        ) {
          context.commit(types.USER_SET_HASH, resp.result.extension_attributes.customer_hash)
        }
        return resp
      })
  },

  resetPasswordByPhone (context, { phone, password, confirmationCode }) {
    context.dispatch('setUserRecoveryInfoByPhone', { phone: phone, code: confirmationCode })
    return fetch(rootStore.state.config.users.reset_password_by_phone_endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ phone, password, confirmationCode })
    }).then(result => result.json())
      .then(result => {
        if (result.code === 200) {
          context.commit(types.USER_SET_HASH, result.result)
          return result
        } else {
          console.error('Error with response - bad content-type!')
        }
        return result
      })
  },

  setUserRecoveryInfoByPhone (context, { phone, code }) {
    if (typeof phone !== 'undefined') {
      context.commit(types.USER_SET_PHONE, phone)
    }
    if (typeof code !== 'undefined') {
      context.commit(types.USER_SET_CONFIRM_CODE, code)
    }
  },

  resetPassword (context, { token, password, id }) {
    return fetch(rootStore.state.config.users.reset_password_by_mail_endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ resetToken: token, newPassword: password, id })
    }).then(response => {
      const contentType = response.headers.get('content-type')
      if (contentType) {
        return response.json()
      } else {
        console.error('Error with response - bad content-type!')
      }
      return response
    })
  },

  sendEmailPasswordReset (context, { email }) {
    return fetch(rootStore.state.config.users.reset_password_endpoint, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: email })
    }).then((response) => {
      return response
    })
  },

  update (context, userData) {
    const ajv = new Ajv()
    const userProfileSchema = require('./userProfile.schema.json')
    const userProfileSchemaExtension = require('./userProfile.schema.extension.json')
    const validate = ajv.compile(Object.assign(userProfileSchema, userProfileSchemaExtension))
    if (!validate(userData)) { // schema validation of user profile data
      rootStore.dispatch('notification/spawnNotification', {
        type: 'error',
        message: i18n.t('Internal validation error. Please check if all required fields are filled in. Please contact us on contributors@vuestorefront.io'),
        action1: { label: i18n.t('OK') }
      })
      throw new ValidationError(validate.errors)
    } else {
      return TaskQueue.execute({ url: rootStore.state.config.users.me_endpoint,
        payload: {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          mode: 'cors',
          body: JSON.stringify(userData)
        },
        callback_event: 'store:user/userAfterUpdate'
      })
    }
  },

  confirmAccountByEmail (context, data) {
    return fetch(rootStore.state.config.users.register_confirmaiton_endpoint, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(resp => { return resp.json() })
      .then((resp) => resp)
  },
  eventSignCustomer (context, data) {
    Vue.prototype.$bus.$emit('notification-progress-start', 'Ваша заявка отправляется')

    let headersObj = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if (data instanceof FormData) {
      headersObj = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'multipart/form-data, boundary=something'
      }
      data.set('uuid', rootStore.state.ui.mindbox.mindboxUUID)
    } else {
      data.uuid = rootStore.state.ui.mindbox.mindboxUUID
    }
    return axios({
      url: rootStore.state.config.users.event_user_create,
      method: 'POST',
      data,
      headers: headersObj
    })
      .then(resp => {
        Vue.prototype.$bus.$emit('notification-progress-stop')
        return resp
      })
      .catch(error => {
        console.error(error)
        Vue.prototype.$bus.$emit('notification-progress-stop')
        rootStore.dispatch('message/showMessage', 'Сервис регистрации временно недоступен, повторите попытку позже')
      })
  },
  changePassword (context, passwordData) {
    return TaskQueue.execute({ url: rootStore.state.config.users.changePassword_endpoint,
      payload: {
        method: 'POST',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(passwordData)
      }
    })
  },
  sendFeedbackForm (context, data) {
    let headersObj = {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    }
    if (data instanceof FormData) {
      headersObj = {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'multipart/form-data, boundary=something'
      }
    }
    return axios.post(rootStore.state.config.users.feedback_endpoint,
      data,
      {
        headers: headersObj
      }
    )
  },
  confirmUserAge ({ commit }) {
    commit(types.USER_AGE_CONFIRM)
  }
}

export default actions
