export const store = {
  namespaced: true,
  state: {
    open: false
  },
  mutations: {
    setNotificationOpen (state, value) {
      state.open = value
    }
  },
  actions: {
    toggleOpen ({ commit }, value) {
      commit('setNotificationOpen', value)
    }
  }
}
