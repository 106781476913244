import { Module } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import LandingPagesState from '../types/LandingPagesState'
import actions from './actions'
import mutations from './mutations'

export const module: Module<LandingPagesState, RootState> = {
  namespaced: true,
  state: {
    data: null
  },
  actions,
  mutations
}
