<template>
  <router-link
    v-observe-visibility="visibilityChanged"
    :to="productLink"
    @click.native="closeMicrocart"
    class="product flex bg-cl-primary align-center w-100 h-100 px12 py40 border-box relative pointer no-underline"
  >
    <product-label v-if="product.name_label"
                   :bg-color="product.background_label"
                   :text-color="product.color_label"
                   :text="product.name_label"
    />
    <div class="label-image relative" />
    <div
      class="product-container w-100 h-100 flex center-xs pb20"
      data-testid="productLink"
    >
      <div class="w-100 product-image relative flex">
        <img :key="thumbnail.src"
             v-lazy="thumbnail"
             :src="thumbnail.src"
             :alt="product.name"
             :title="product.name"
             class="nogrow"
             data-testid="productImage"
        >
      </div>
      <div class="w-100 infoProduct">
        <p class="product-title m0 lh22 fs14 cl-black weight-500 mb10">
          {{ currentProductName | htmlDecode }}
        </p>
        <div class="flex center-xs middle-xs fs12 lh19 attr-help mb20">
          <div class="mr5">
            {{ currentBottleVolume }}
          </div>
          <div>
            {{ currentAlcoholPercentage }}
          </div>
        </div>
        <template v-if="isCheckoutMode && showPrice">
          <div v-if="product.special_price && product.price && isSpecialToDate" class="w-100 mt10">
            <span
              v-if="product.special_price && parseFloat(product.special_price)"
              class="price serif weight-600 fs17 cl-black ml4 mr5"
            >
              {{ product.special_price | price }}
            </span>
            <span
              v-if="product.special_price && parseFloat(product.originalPrice)"
              class="price serif weight-300 fs14 cl-dark-gray price-original"
            >
              {{ product.originalPrice | price }}
            </span>
          </div>
          <div v-if="!product.special_price || !isSpecialToDate" class="w-100 mt1p">
            <span
              v-if="!productVipPrice"
              class="price weight-600 fs18 lh21 cl-black"
            >
              <template v-if="configProductPrice">
                от {{ configProductPrice | price }}
              </template>
              <template v-if="!configProductPrice && simpleProductPrice">
                {{ simpleProductPrice | price }}
              </template>
            </span>
            <span
              v-else-if="parseInt(product.priceInclTax)"
              :class="{
                'price-original': productVipPrice
              }"
              class="price serif weight-600 fs14 cl-black"
            >
              {{ product.priceInclTax | price }}
            </span>
            <span
              v-if="productVipPrice"
              class="price serif weight-600 fs14 cl-red-dark ml4"
            >
              {{ productVipPrice | price }}
            </span>
          </div>
        </template>
      </div>
    </div>
    <div class="product-button weight-600 fs12 lh14 flex center-xs middle-xs ls4 bg-cl-black cl-white uppercase pointer">
      Купить
    </div>
  </router-link>
</template>

<script>
import { productThumbnailPath } from '@vue-storefront/core/helpers'
import rootStore from '@vue-storefront/core/store'
import { ProductTile } from '@vue-storefront/core/modules/catalog/components/ProductTile.ts'
import productModel from 'theme/mixins/Product/productModel'
import productRouterLinkModel from 'theme/mixins/Product/productRouterLinkModel'
import AdaptiveSizeClient from 'theme/components/core/AdaptiveSizeClient'
import ProductLabel from 'theme/components/theme/blocks/ProductLabel/ProductLabel.vue'
import NoSSR from 'vue-no-ssr'

export default {
  components: {
    'no-ssr': NoSSR,
    ProductLabel
  },
  mixins: [ProductTile, productModel, productRouterLinkModel, AdaptiveSizeClient],
  props: {
    labelsActive: {
      type: Boolean,
      required: false,
      default: true
    },
    isLargeProduct: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    isSpecialToDate () {
      return this.product.special_to_date ? new Date(this.product.special_to_date) > new Date() : false
    },
    isMobile () {
      return this.windowWidth < 1024
    },
    currentAlcoholPercentage () {
      return this.product && this.product.bq_4747 ? `${this.product.bq_4747}%` : ''
    },
    currentChildren () {
      if (this.product && this.product.configurable_children && this.product.configurable_children.length) {
        return this.product.configurable_children.find(children => children.bottle_volume)
      }
      return null
    },
    currentBottleVolume () {
      const configVolumes = this.$store.state.config.bottleValues
      const currentProductVolume = this.product && this.product.bottle_volume ? this.product.bottle_volume : ''
      const currentChilderVolume = this.currentChildren ? this.currentChildren.bottle_volume : currentProductVolume
      return configVolumes[currentChilderVolume] ? `${configVolumes[currentChilderVolume]}л` : ''
    },
    currentProductName () {
      const productCategory = this.product.bq_4740 ? `${this.product.bq_4740} ` : ''
      return `${productCategory}${this.product.name}`
    },
    thumbnail () {
      const configThumbnail = productThumbnailPath(this.sampleProduct ? this.sampleProduct : this.product)
      const defaultThumb = this.product.thumbnail
      const thumbnail = configThumbnail || defaultThumb
      let src
      if (this.isLargeProduct) {
        src = this.getThumbnail('/catalog/product/' + thumbnail, 700, 845)
      } else {
        src = this.getThumbnail('/catalog/product/' + thumbnail, 273, 273)
      }
      return {
        src,
        loading: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAOCAQAAAC8qkUgAAAAEElEQVR42mNkwAIYRwVRAQAJwwAPwaH+UgAAAABJRU5ErkJggg==',
        error: '/assets/placeholder.jpg'
      }
    },
    isCheckoutMode () {
      return parseInt(this.$store.state.config.shopSettings.checkoutMode) || this.isSpecialEvent
    },
    productVipPrice () {
      if (this.isVipCategory && this.product.catalog_price_rule) {
        const priceRules = this.product.catalog_price_rule
        const priceRulesKeys = Object.keys(this.product.catalog_price_rule)
        const vipPriceIndex = priceRulesKeys.find(ruleIndex => {
          return priceRules[ruleIndex] && priceRules[ruleIndex]['customerGroupName'] && priceRules[ruleIndex]['customerGroupName'] === 'Customer Vip'
        })
        if (vipPriceIndex) {
          return priceRules[vipPriceIndex]['price_with_discount']
        }
      }
      return 0
    },
    showPrice () {
      if (this.isVipUser) {
        return true
      }
      if (this.$store.state.config?.products?.disablePriceOnEventPage.includes(this.$route?.params?.eventCode)) {
        return false
      }
      const simpleShowPrice = !!this.product.show_price
      if (this.product.type_id === 'simple') {
        return simpleShowPrice
      }
      const configChildrens = this.product.configurable_children || []
      const configShowPrice = configChildrens.find(item => !!item.show_price)
      return configShowPrice || simpleShowPrice
    },
    isVipUser () {
      return this.$store.state.user.current ? this.$store.state.user.current.extension_attributes.vip : null
    },
    isProductConfig () {
      return this.product.type_id === 'configurable'
    },
    configProductPrice () {
      if (
        this.isProductConfig &&
        this.product.configurable_children &&
        this.product.configurable_children.length > 1
      ) {
        const currentUserGroupId = this.$store.state.user.current ? this.$store.state.user.current.group_id : null
        const filteredConfigChildrens = this.product.configurable_children
          .filter(productOption => parseInt(productOption.bottle_volume) > 236)

        if (currentUserGroupId) {
          const pricesForUserGroup = filteredConfigChildrens.map(child => {
            return child.catalog_price_rule && child.catalog_price_rule[currentUserGroupId]
              ? child.catalog_price_rule[currentUserGroupId].price_with_discount
              : null
          })
          let currentUserGroupMinPrice = Math.min(...pricesForUserGroup)
          if (currentUserGroupMinPrice) {
            return currentUserGroupMinPrice
          }
        }

        const productOptionsPrices = filteredConfigChildrens.map(productOption => productOption.price).filter(price => !!price)
        return Math.min(...productOptionsPrices)
      }
      return 0
    },
    simpleProductPrice () {
      const currentUserGroupId = this.$store.state.user.current ? this.$store.state.user.current.group_id : null
      const currentProduct = this.product.type_id === 'simple' ? this.product : this.product.configurable_children[0]
      return currentProduct.catalog_price_rule && currentProduct.catalog_price_rule[currentUserGroupId]
        ? currentProduct.catalog_price_rule[currentUserGroupId].price_with_discount
        : currentProduct.price
    },
    productLink () {
      var productUrl
      if (this.$route.query.ecode) {
        if (typeof this.productRouterLink === 'object') {
          productUrl = this.productRouterLink
          productUrl.query = { ecode: this.$route.query.ecode }
        } else if (typeof this.productRouterLink === 'string') {
          productUrl = this.productRouterLink + '?ecode=' + this.$route.query.ecode
        }
      } else {
        productUrl = this.productRouterLink
      }
      return productUrl
    }
  },
  beforeMount () {
    this.$bus.$on('product-after-priceupdate', this.onProductPriceUpdate)
  },
  beforeDestroy () {
    this.$bus.$off('product-after-priceupdate', this.onProductPriceUpdate)
  },
  methods: {
    onProductPriceUpdate (product) {
      if (product.sku === this.product.sku) {
        Object.assign(this.product, product)
      }
    },
    closeMicrocart () {
      this.$store.commit('ui/setMicrocart', false)
    },
    visibilityChanged (isVisible) {
      if (isVisible) {
        if (rootStore.state.config.products.configurableChildrenStockPrefetchDynamic && rootStore.products.filterUnavailableVariants) {
          const skus = [this.product.sku]
          if (this.product.type_id === 'configurable' && this.product.configurable_children && this.product.configurable_children.length > 0) {
            for (const confChild of this.product.configurable_children) {
              const cachedItem = rootStore.state.stock.cache[confChild.id]
              if (typeof cachedItem === 'undefined' || cachedItem === null) {
                skus.push(confChild.sku)
              }
            }
            if (skus.length > 0) {
              rootStore.dispatch('stock/list', { skus: skus }) // store it in the cache
            }
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/animations/transitions';
@import '~theme/css/variables/colors';
@import '~theme/css/helpers/functions/color';
@import '~theme/css/variables/_grid';

$bg-secondary: color(secondary, $colors-background);
$border-secondary: color(secondary, $colors-border);
$color-white: color(white);
.product-button {
  display: none;
  width: calc(100% - 40px);
  height: 50px;
  margin: 0 auto;
}
.label-image {
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 1;
  @media #{$media-xs} {
    width: 90px;
    height: 25px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
}
.stars {
  @media #{$media-xs} {
    margin-bottom: 10px;
    .rating,
    .count-review {
      font-size: 12px;
      line-height: 14px;
    }
    .rating {
      letter-spacing: 1px;
    }
    .count-review {
      letter-spacing: 2px;
    }
  }
}
.attr-help {
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.6);
  @media #{$media-xs} {
    margin-bottom: 5px;
  }
}
.product {
  max-height: 100%;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.05);
    box-shadow: 0px 4px 24px rgba(121, 121, 121, 0.23);
    z-index: 2;
    .product-button {
      display: flex;
    }
  }
  @media #{$media-xs} {
    &:hover {
      transform: scale(1);
      box-shadow: none;
      z-index: 1;
      .product-button {
        display: none;
      }
    }
  }
}
.product-container {
  flex-wrap: wrap;
  @media #{$media-xs} {
    padding-bottom: 0;
  }
}
.product-title {
  letter-spacing: 0.5px;
}

.price-original {
  text-decoration: line-through;
}

%label {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: $border-secondary;
  text-transform: uppercase;
  color: $color-white;
  font-size: 12px;
}
.infoProduct {
  min-height: 114px;
  @media #{$media-xs} {
    min-height: 90px;
  }
}
.product-image {
  width: 100%;
  height: 273px;
  margin: 0 auto;
  align-items: flex-start;
  @media #{$media-to-sm} {
    height: 190px;
  }
  @media #{$media-mds} {
    height: 165px;
  }
  img {
    width: auto;
    max-width: 95%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    mix-blend-mode: darken;
    transform: scale(1);
    transition: 0.3s opacity $motion-main, 0.3s transform $motion-main;
    object-fit: contain;
    &[lazy="loaded"] {
      animation: products-loaded;
      animation-duration: 0.3s;
    }
    &[lazy="loading"] {
      filter: blur(3px);
    }

    @keyframes products-loaded {
      from {
        opacity: 0;
      }
      to {
        opacity: 0.8;
      }
    }
  }
}
@media #{$media-xlx} {
  .product-title {
    font-size: 14px;
  }
}
@media #{$media-to-sm} {
  .product {
    padding: 20px 10px;
  }
  .product-title {
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 13px;
  }
}
@media #{$media-xs} {
  .product-title {
    box-sizing: border-box;
    margin-bottom: 5px;
  }
  .price {
    font-size: 14px;
    line-height: 22px;
  }
}
</style>
