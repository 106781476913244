import { GetterTree } from 'vuex'
import sumBy from 'lodash-es/sumBy'
import i18n from '@vue-storefront/i18n'
import CartState from '../types/CartState'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'

const getters: GetterTree<CartState, RootState> = {
  totals (state) {
    if (state.cartItems) {
      const { value } = rootStore.getters['cart/getTotals'].find(total => total.code === 'discount') || { value: 0 }
      return {
        code: 'grand_total',
        title: i18n.t('Grand total'),
        value: sumBy(state.cartItems, (p) => {
          const currentUserGroupId = rootStore.state.user.current ? rootStore.state.user.current.group_id : null
          if (currentUserGroupId) {
            return p.catalog_price_rule && p.catalog_price_rule[currentUserGroupId]
              ? p.qty * p.catalog_price_rule[currentUserGroupId].price_with_discount
              : p.qty * p.price + value
          }
          const discount_val = (p.totals) ? p.totals.discount_amount : null
          return p.qty * p.price - p.qty * discount_val
        }),
        valueWithoutCoupon: sumBy(state.cartItems, (p) => {
          return p.qty * p.price
        })
      }
    } else {
      let shipping = state.shipping instanceof Array ? state.shipping[0] : state.shipping
      let payment = state.payment instanceof Array ? state.payment[0] : state.payment
      if (shipping && payment) {
        return [
          {
            code: 'subtotalInclTax',
            title: i18n.t('Subtotal incl. tax'),
            value: sumBy(state.cartItems, (p) => {
              return p.qty * p.priceInclTax
            })
          },
          {
            code: 'payment',
            title: i18n.t(payment.title),
            value: payment.costInclTax
          },
          {
            code: 'shipping',
            title: i18n.t(shipping.method_title),
            value: shipping.price_incl_tax
          },
          {
            code: 'grand_total',
            title: i18n.t('Grand total'),
            value: sumBy(state.cartItems, (p) => {
              return p.qty * p.priceInclTax + shipping.price_incl_tax
            })
          }
        ]
      } else {
        return []
      }
    }
  }
}

export default getters
