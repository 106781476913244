const mutations = {
  NEWS_SET_POSTS (state, data) {
    if (data.updateList) {
      state.items = state.items.concat(data.items)
    } else {
      state.items = data.items
    }
  },
  NEWS_SET_CURRENT_POST (state, data) {
    state.current = data
  },
  NEWS_SET_TOTAL (state, data) {
    state.total = data
  },
  NEWS_SET_LAST_ITEMS (state, data) {
    state.lastItems = data
  },
  NEWS_SET_INFO (state, data) {
    state.info = data
  }
}

export default mutations
