var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.productsWithBanners && _vm.productsWithBanners.length
      ? _c(
          "div",
          { staticClass: "product-listing m0 flex flex-wrap middle-xs w-100" },
          [
            _vm._l(_vm.productsWithBanners, function(product, index) {
              return [
                _c(
                  "div",
                  {
                    key: product.banner_id
                      ? parseInt(product.banner_id) + index
                      : product.id,
                    ref: "product",
                    refInFor: true,
                    staticClass: "border-box col-xs-6 col-md-3 product-item"
                  },
                  [
                    !product.banner_id
                      ? _c("product-tile", { attrs: { product: product } })
                      : _c("product-banner", {
                          staticClass: "product-banner",
                          attrs: { banner: product }
                        })
                  ],
                  1
                )
              ]
            }),
            _vm._v(" "),
            _c("div", { staticClass: "float-clear w-100" })
          ],
          2
        )
      : _c("div", { staticClass: "fs22 empty-category-text" }, [
          _vm._v("\n    " + _vm._s(_vm.emptyCategoryText) + "\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }