import { ActionTree } from 'vuex'

export const actions: ActionTree<any, any> = {
  showMessage ({ commit, state }, message) {
    commit('addMessage', message)
    if (state.isNotificationHidden) {
      setTimeout(() => {
        commit('removeMessage')
      }, 4000)
    }
  },
  addServerMessage ({commit}, message) {
    commit('addServerMessage', message)
  },
  removeServerMessage ({commit}) {
    commit('removeServerMessage')
  },
  closeMessage ({commit, dispatch}) {
    commit('removeMessage')
    dispatch('toggleNotificationHidden', true)
  },
  toggleNotificationHidden ({commit}, isHidden) {
    commit('toggleNotificationHidden', isHidden)
  }
}
export default actions
