import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import ProductState from '../../types/ProductState'

const mutations: MutationTree<ProductState> = {
  /**
   * Store Sliders by identifier in state
   * @param {} state
   * @param {Array} slider
   */
  [types.ADD_CATEGORY_SLIDERS] (state, sliders) {
    if (!state.categoryBannersList.find(e => e.id === sliders.id)) {
      state.categoryBannersList = state.categoryBannersList.concat(sliders)
    }
  },
  [types.REMOVE_CATEGORY_SLIDERS] (state, banners) {
    let array = state.categoryBannersList
    banners.forEach(item => {
      state.categoryBannersList.forEach((elem, index) => {
        if (elem.banner_id === item.banner_id) {
          array.splice(index, 1)
        }
      })
    })
    state.categoryBannersList = array
  },
  [types.CATALOG_SET_ROOT] (state, value) {
    state.productRoot = value
  },
  [types.CATALOG_SET_NEW_PRODUCT] (state, products) {
    state.newProductList = products
  },
  [types.CATALOG_SET_VIEWED_PRODUCT] (state, { product, index }) {
    if (index >= 0) {
      state.viewedProducts.splice(index, 1)
    }
    if (product?.length && state?.viewedProducts?.length >= 5) {
      state?.viewedProducts?.pop()
    }
    state.viewedProducts = [...product, ...state.viewedProducts]
  },
  [types.CATALOG_RESET_VIEWED_PRODUCT] (state, {productId}) {
    if (!productId) {
      state.viewedProducts = []
    }
    state.viewedProducts = state.viewedProducts.filter(product => product.id !== productId)
  },
  [types.CATALOG_UPD_BEST_SELLER] (state, products) {
    state.bestSellers = products
  }
}

export default mutations
