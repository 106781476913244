import Vue from 'vue'
import { ActionTree } from 'vuex'
import * as types from './mutation-types'
import { configureProductAsync,
  calculateTaxes } from '@vue-storefront/core/modules/catalog/helpers'
import { entityKeyName } from '@vue-storefront/core/store/lib/entities'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '../../types/ProductState'
import { optionLabel } from '@vue-storefront/core/modules/catalog/helpers/optionLabel'
import { formatBreadCrumbRoutes, isServer } from '@vue-storefront/core/helpers'
import trim from 'lodash-es/trim'

const insertIntoArray = (array, item, index) => {
  let tmp = array.splice(index)
  return array.concat(item, tmp)
}

const randomInteger = (min, max) => {
  let rand = min - 0.5 + Math.random() * (max - min + 1)
  rand = Math.round(rand)
  return rand
}

/**
 * Adds an banners (insert) to an products (target) and returns it.
 * @return [target]
 * @param target
 * @param insert
 */

const mixer = (target, insert = [], isPresentsPage) => {
  let largeIndex = [2, 12, 15, 25]
  let middleIndex = [3, 6, 8, 10, 13, 16, 18, 20, 23, 26, 28]
  let smallIndex = [4, 7, 8, 10, 14, 17, 18, 20, 24, 27, 28]
  let indexFilter = []
  let randomIndex = 0
  let sizeBlock = Math.floor(target.length / insert.length)
  let from = 0
  let to = sizeBlock

  if (isPresentsPage && insert.length) {
    largeIndex = [2, 5, 12, 15, 22, 25, 32, 35, 42, 45, 52, 55]
    insert.forEach((item, index) => {
      target = insertIntoArray(target, item, largeIndex[index])
    })
    return target
  }
  if (insert.length) {
    insert.forEach((item, index) => {
      switch (item.size) {
        case 'large':
          indexFilter = largeIndex.filter(e => e > from && e < to)
          break

        case 'middle':
          indexFilter = middleIndex.filter(e => e > from && e < to)
          break

        case 'small':
          indexFilter = smallIndex.filter(e => e > from && e < to)
          break
      }

      randomIndex = randomInteger(0, indexFilter.length - 1)
      target = insertIntoArray(target, item, indexFilter[randomIndex])
      if (item.size === 'middle') {
        target = insertIntoArray(target, item, indexFilter[randomIndex] + 1)
        sizeBlock += 1
      }
      from = to
      to = sizeBlock * (index + 2)
    })
    return target
  }
}

const actions: ActionTree<ProductState, RootState> = {
  /**
   * Search ElasticSearch catalog of products using simple text query
   * Use bodybuilder to build the query, aggregations etc: http://bodybuilder.js.org/
   * @param {Object} query is the object of searchQuery class
   * @param {Int} start start index
   * @param {Int} size page size
   * @return {Promise}
   */
  list (context, { query, start = 0, size = 50, entityType = 'product', sort = '', cacheByKey = 'sku', prefetchGroupProducts = true, updateState = false, meta = {}, excludeFields = null, includeFields = null, configuration = null, append = false, populateRequestCacheTags = true, banners = [], isPresentsPage = false }) {
    let isCacheable = (includeFields === null && excludeFields === null)
    if (isCacheable) {
      console.debug('Entity cache is enabled for productList')
    } else {
      console.debug('Entity cache is disabled for productList')
    }

    if (rootStore.state.config.entities.optimize) {
      if (excludeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        excludeFields = rootStore.state.config.entities.product.excludeFields
      }
      if (includeFields === null) { // if not set explicitly we do optimize the amount of data by using some default field list; this is cacheable
        includeFields = rootStore.state.config.entities.product.includeFields
      }
    }
    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields }).then((resp) => {
      if (resp.items && resp.items.length) { // preconfigure products; eg: after filters
        for (let product of resp.items) {
          if (populateRequestCacheTags && Vue.prototype.$ssrRequestContext) {
            Vue.prototype.$ssrRequestContext.output.cacheTags.add(`P${product.id}`)
          }
          product.errors = {} // this is an object to store validation result for custom options and others
          product.info = {}
          if (!product.parentSku) {
            product.parentSku = product.sku
          }
          if (rootStore.state.config.products.setFirstVarianAsDefaultInURL && product.hasOwnProperty('configurable_children') && product.configurable_children.length > 0) {
            product.sku = product.configurable_children[0].sku
          }
          if (configuration) {
            let selectedVariant = configureProductAsync(context, { product: product, configuration: configuration, selectDefaultVariant: false })
            Object.assign(product, selectedVariant)
          }
        }
      }
      return calculateTaxes(resp.items, context).then(() => {
        // handle cache
        const cache = Vue.prototype.$db.elasticCacheCollection
        for (let prod of resp.items) { // we store each product separately in cache to have offline access to products/single method
          if (prod.configurable_children) {
            for (let configurableChild of prod.configurable_children) {
              if (configurableChild.custom_attributes) {
                for (let opt of configurableChild.custom_attributes) {
                  configurableChild[opt.attribute_code] = opt.value
                }
              }
            }
          }
          if (!prod[cacheByKey]) {
            cacheByKey = 'id'
          }
          const cacheKey = entityKeyName(cacheByKey, prod[cacheByKey])
          if (isCacheable) { // store cache only for full loads
            cache.setItem(cacheKey, prod)
              .catch((err) => {
                console.error('Cannot store cache for ' + cacheKey, err)
              })
          }
          if ((prod.type_id === 'grouped' || prod.type_id === 'bundle') && prefetchGroupProducts) {
            context.dispatch('setupAssociated', { product: prod })
          }
        }
        // commit update products list mutation
        if (banners[0] && !resp.items.find(e => e.banner_id) && (Object.keys(rootStore.state.category.filters.chosen).length === 0 || isPresentsPage)) {
          if (isPresentsPage) {
            banners.sort((a, b) => { return a.sorting - b.sorting })
            if (Object.keys(rootStore.state.category.filters.chosen).length) {
              const productSkus = resp.items.map(item => item.sku)
              banners = banners.filter(banner => productSkus.includes(banner.product_sku))
            }
          } else {
            banners.splice(Math.ceil(resp.items.length / (rootStore.state.config.products.pageSize / rootStore.state.config.products.bannersPerPage)))
          }
          if (banners.length) {
            const bannersProductSkus = banners.map(banner => banner.product_sku)
            resp.items = resp.items.filter(item => !bannersProductSkus.includes(item.sku))
            Object.assign(resp, {items: mixer(resp.items, banners, isPresentsPage)})
            rootStore.dispatch('product/removeCategoryBanners', banners)
          }
        }
        if (updateState) {
          context.commit(types.CATALOG_UPD_PRODUCTS, { products: resp, append: append })
        }
        Vue.prototype.$bus.$emit('product-after-list', { query: query, start: start, size: size, sort: sort, entityType: entityType, meta: meta, result: resp })
        return resp
      })
    })
  },
  fetchProductByCustomQuery (context, { query, start = 0, size = 50, entityType = 'product', sort = '' }) {
    const excludeFields = rootStore.state.config.entities.product.excludeFields
    const includeFields = rootStore.state.config.entities.product.includeFields

    return quickSearchByQuery({ query, start, size, entityType, sort, excludeFields, includeFields }).then((resp) => {
      const product = resp.items && resp.items.length ? resp.items[0] : null
      if (!product) {
        throw new Error('product/fetchProductByUrlKey query returned empty result')
      }
      return product
    }).catch((err) => {
      console.error(err)
    })
  },
  addCategoryBanners (context, sliders) {
    let array = []
    if (sliders && sliders.length) {
      array = sliders.filter(e => e.size)
    }
    context.commit(types.ADD_CATEGORY_SLIDERS, array)
    return array
  },
  removeCategoryBanners (context, banners) {
    context.commit(types.REMOVE_CATEGORY_SLIDERS, banners)
  },
  setupVariants (context, { product }) {
    let subloaders = []
    if (product.type_id === 'configurable' && product.hasOwnProperty('configurable_options')) {
      let attributeKey = 'attribute_id'
      const configurableAttrKeys = product.configurable_options.map(opt => {
        if (opt.attribute_id) {
          attributeKey = 'attribute_id'
          return opt.attribute_id
        } else {
          attributeKey = 'attribute_code'
          return opt.attribute_code
        }
      })
      subloaders.push(context.dispatch('attribute/list', {
        filterValues: configurableAttrKeys,
        filterField: attributeKey
      }, { root: true }).then(() => {
        context.state.current_options = {
        }

        for (let option of product.configurable_options) {
          for (let ov of option.values) {
            let lb = ov.label ? ov.label : optionLabel(context.rootState.attribute, { attributeKey: option.attribute_id, searchBy: 'id', optionId: ov.value_index })
            if (trim(lb) !== '') {
              let optionKey = option.attribute_code ? option.attribute_code : option.label.toLowerCase()
              if (!context.state.current_options[optionKey]) {
                context.state.current_options[optionKey] = []
              }
              context.state.current_options[optionKey].push({
                label: lb,
                id: ov.value_index,
                attribute_code: option.attribute_code
              })
            }
          }
        }
        Vue.set(context.state, 'current_options', context.state.current_options)
      }).catch(err => {
        console.error(err)
      }))
    }
    return Promise.all(subloaders)
  },
  setupBreadcrumbs (context, { product }) {
    let subloaders = []
    let breadcrumbsName = null
    let breadCrumbRoutes = null
    let setbrcmb = (path) => {
      if (path.findIndex(itm => {
        return itm.slug === context.rootState.category.current.slug
      }) < 0) {
        path.push({
          slug: context.rootState.category.current.slug,
          name: context.rootState.category.current.name
        }) // current category at the end
      }
      // depreciated, TODO: base on breadcrumbs module
      context.state.breadcrumbs.routes = formatBreadCrumbRoutes(path) // TODO: change to store.commit call?
    }

    if (product.category && product.category.length) {
      const catalogTypes = {
        'category': 'Весь каталог',
        'brand': 'Pim'
      }
      const currentRootName = context.state.productRoot && catalogTypes[context.state.productRoot]
        ? catalogTypes[context.state.productRoot]
        : catalogTypes.category
      const categoryIds = product.category
        .filter(category => category.parent === currentRootName)
        .map(cat => cat.category_id)

      subloaders.push(
        context.dispatch('category/list', {}, { root: true }).then((categories) => {
          const catList = []

          for (let catId of categoryIds) {
            let category = categories.items.find((itm) => { return itm['id'] === parseInt(catId) })
            if (category) {
              catList.push(category)
            }
          }

          const rootCat = catList.shift()
          let catForBreadcrumbs = rootCat
          for (let cat of catList) {
            const catPath = cat.path
            if (catPath && catPath.includes(rootCat.path) && (catPath.split('/').length > catForBreadcrumbs.path.split('/').length)) {
              catForBreadcrumbs = cat
            }
          }
          context.dispatch('category/single', { key: 'id', value: catForBreadcrumbs.id }, { root: true }).then(() => { // this sets up category path and current category
            setbrcmb(context.rootState.category.current_path)
          }).catch(err => {
            setbrcmb(context.rootState.category.current_path)
            console.error(err)
          })
        }).catch(err => {
          console.error(err)
        })
      )
    }
    // TODO: To repreciate and use breadcrumbs module
    context.state.breadcrumbs.name = product.name
    breadcrumbsName = product.name
    const breadcrumbs = {
      routes: breadCrumbRoutes,
      current: breadcrumbsName
    }
    context.dispatch('breadcrumbs/set', breadcrumbs, { root: true })
    return Promise.all(subloaders)
  },
  setProductRoot ({ commit }, root = '') {
    commit(types.CATALOG_SET_ROOT, root)
  },
  fetch (context, { parentSku, childSku = null }) {
    // pass both id and sku to render a product
    const productSingleOptions = {
      sku: parentSku,
      childSku: childSku
    }
    return context.dispatch('single', { options: productSingleOptions }).then((product) => {
      // KT CHANGES START
      // if (product.status >= 2) {
      //   throw new Error(`Product query returned empty result product status = ${product.status}`)
      // }
      // if (product.visibility === 1) { // not visible individually (https://magento.stackexchange.com/questions/171584/magento-2-table-name-for-product-visibility)
      //   throw new Error(`Product query returned empty result product visibility = ${product.visibility}`)
      // }
      // KT CHANGES END

      let subloaders = []
      if (product) {
        const productFields = Object.keys(product).filter(fieldName => {
          return rootStore.state.config.entities.product.standardSystemFields.indexOf(fieldName) < 0 // don't load metadata info for standard fields
        })
        const attributesPromise = context.dispatch('attribute/list', { // load attributes to be shown on the product details - the request is now async
          filterValues: rootStore.state.config.entities.product.useDynamicAttributeLoader ? productFields : null,
          only_visible: rootStore.state.config.entities.product.useDynamicAttributeLoader === true,
          only_user_defined: true,
          includeFields: rootStore.state.config.entities.optimize ? rootStore.state.config.entities.attribute.includeFields : null
        }, { root: true }) // TODO: it might be refactored to kind of: `await context.dispatch('attributes/list) - or using new Promise() .. to wait for attributes to be loaded before executing the next action. However it may decrease the performance - so for now we're just waiting with the breadcrumbs
        if (isServer) {
          subloaders.push(context.dispatch('setupBreadcrumbs', { product: product }))
          subloaders.push(context.dispatch('filterUnavailableVariants', { product: product }))
        } else {
          attributesPromise.then(() => context.dispatch('setupBreadcrumbs', { product: product })) // if this is client's side request postpone breadcrumbs setup till attributes are loaded to avoid too-early breadcrumb switch #2469
          context.dispatch('filterUnavailableVariants', { product: product }) // exec async
        }
        subloaders.push(attributesPromise)

        // subloaders.push(context.dispatch('setupVariants', { product: product })) -- moved to "product/single"
        /* if (product.type_id === 'grouped' || product.type_id === 'bundle') { -- moved to "product/single"
          subloaders.push(context.dispatch('setupAssociated', { product: product }).then((subloaderresults) => {
            context.dispatch('setCurrent', product) // because setup Associated can modify the product price we need to update the current product
          }))
        } */

        context.dispatch('setProductGallery', { product: product })

        if (rootStore.state.config.products.preventConfigurableChildrenDirectAccess) {
          subloaders.push(context.dispatch('checkConfigurableParent', { product: product }))
        }
      } else { // error or redirect

      }
      return subloaders
    })
  },
  priceRequest (context, data) {
    const url = rootStore.state.config.products.price_request_endpoint
    return fetch(url, { method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  },
  setNewProduct (context, products) {
    context.commit(types.CATALOG_SET_NEW_PRODUCT, products)
  },
  setViewedProduct (context, {product, index}) {
    context.commit(types.CATALOG_SET_VIEWED_PRODUCT, {product, index})
  },
  resetViewedProduct (context, {productId}) {
    context.commit(types.CATALOG_RESET_VIEWED_PRODUCT, {productId})
  },
  setBestSellers (context, products) {
    context.commit(types.CATALOG_UPD_BEST_SELLER, products)
  }
}

export default actions
