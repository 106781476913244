import { render, staticRenderFns } from "./ProductLabel.vue?vue&type=template&id=675aa5da&scoped=true&"
import script from "./ProductLabel.vue?vue&type=script&lang=js&"
export * from "./ProductLabel.vue?vue&type=script&lang=js&"
import style0 from "./ProductLabel.vue?vue&type=style&index=0&id=675aa5da&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675aa5da",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/opt/maxxium-stanalone/maxxium-storefront/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('675aa5da')) {
      api.createRecord('675aa5da', component.options)
    } else {
      api.reload('675aa5da', component.options)
    }
    module.hot.accept("./ProductLabel.vue?vue&type=template&id=675aa5da&scoped=true&", function () {
      api.rerender('675aa5da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/themes/maxxium-theme/components/theme/blocks/ProductLabel/ProductLabel.vue"
export default component.exports