import config from 'config'
import { clearCache } from './cache'

const connect = (store) => {
  const socket = new WebSocket(`${window.location.protocol === 'https:' ? 'wss' : 'ws'}://${window.location.host}/clear-cache`)

  socket.onopen = () => {
    console.log('CLEAR CACHE: Connection established.')
  }

  socket.onmessage = async (msg) => {
    if (msg.data === config.clearCache.websocket.key) {
      clearCache()
      // show the user a message
      store.dispatch('kt-clear-cache/toggleOpen', true)
    }
  }

  socket.onclose = (event) => {
    if (event.wasClean) {
      console.log('CLEAR CACHE: Connection closed clear.')
    } else {
      console.error('CLEAR CACHE: Connection break.') // for example, 'kill' proccess server
    }
    console.log('CLEAR CACHE: Code: ' + event.code + ' reason: ' + event.reason)
    console.log('CLEAR CACHE: Reconnect after 1 second.')
    setTimeout(() => {
      connect(store)
    }, 1000)
  }
}

export {
  connect
}
