import { VueStorefrontModule, VueStorefrontModuleConfig } from '@vue-storefront/core/lib/module'
import rootStore from '@vue-storefront/core/store'

const KEY = 'subscribtion'

const subscribeRequest = ({ url = rootStore.state.config.subscription.url, data = {} } = {}) => {
  return fetch(url, {
    mode: 'cors',
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  }).then(res => res.json())
}

const store = {
  namespaced: true,
  actions: {
    subscribe (state, data) {
      return subscribeRequest({ data })
    },
    subscribeOnMaintenanceWork (state, data) {
      return subscribeRequest({
        url: rootStore.state.config.subscription.maintanceWorkUrl,
        data
      })
    }
  }
}

const moduleConfig: VueStorefrontModuleConfig = {
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] }
}

export const Subscribtion = new VueStorefrontModule(moduleConfig)
