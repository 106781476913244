import { beforeRegistration } from './hooks/beforeRegistration'
import { createModule } from '@vue-storefront/core/lib/module'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const KEY = 'kt-mindBox'

export const KtMindbox = createModule({
  key: KEY,
  beforeRegistration
})
