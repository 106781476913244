import Vue from 'vue'
export class IndexedDB {
  constructor (dbName) {
    this.indexedDB = window.indexedDB
    this.dbName = dbName
    this.version = 1
    this.database = null
  }

  async exist () {
    try {
      const databases = await this.indexedDB.databases()
      if (databases.length && databases.find(db => db.name === this.dbName)) {
        const database = databases.find(db => db.name === this.dbName)
        this.version = database.version
        return true
      }
      return false
    } catch (error) {
      console.error('CLEAR CACHE: ', error)
      return false
    }
  }

  open () {
    return new Promise((resolve, reject) => {
      const openRequest = this.indexedDB.open(this.dbName, this.version)

      openRequest.onerror = () => {
        console.error('Error - ', openRequest.error)
        return reject(openRequest.error)
      }

      openRequest.onsuccess = () => {
        this.database = openRequest.result
        console.log('CLEAR CACHE: Database opened')
        return resolve()
      }
    })
  }

  clearStorage (name) {
    const cacheCollections = {
      attributes: 'attributesCollection',
      carts: 'cartsCollection',
      categories: 'categoriesCollection',
      checkoutFieldValues: 'checkoutFieldsCollection',
      claims: 'claimsCollection',
      cms: 'cmsData',
      compare: 'compareCollection',
      elasticCache: 'elasticCacheCollection',
      orders: 'ordersCollection',
      ordersHistory: 'ordersHistoryCollection',
      products: 'productsCollection',
      syncTasks: 'syncTaskCollection',
      user: 'usersCollection'
    }
    if (cacheCollections[name] && Vue.prototype.$db[cacheCollections[name]]) {
      Vue.prototype.$db[cacheCollections[name]].clear()
    } else {
      console.log('CLEAR CACHE: Storage for clear is not exist')
    }
  }
}
