import { ActionTree } from 'vuex'
import CommentState from '../types/CommentState'
import RootState from '@vue-storefront/core/types/RootState'
import rootStore from '@vue-storefront/core/store'
import i18n from '@vue-storefront/i18n'
import Vue from 'vue'

const actions: ActionTree<CommentState, RootState> = {
  createComment (context, { comment }) {
    let data = {
      comment
    }
    Vue.prototype.$bus.$emit('notification-progress-start', i18n.t('Adding a comment ...'))

    return fetch(`${rootStore.state.config.comment.create_endpoint}`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      mode: 'cors',
      body: JSON.stringify(data)
    }).then(resp => resp.json())
      .then(({ code, result } = {}) => {
        return {
          code,
          result
        }
      }).catch(err => {
        console.error(err)
      }).finally(() => {
        Vue.prototype.$bus.$emit('notification-progress-stop')
      })
  }
}

export default actions
