var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "breadcrumbs fs14 lh22 cl-black flex middle-xs" },
    _vm._l(_vm.breadcrumbRoutes, function(link, index) {
      return _c(
        "div",
        { key: link.route_link, staticClass: "flex middle-xs" },
        [
          !_vm.isLastBreadcrumbs(index)
            ? _c(
                "router-link",
                {
                  staticClass: "border-breadcrumbs",
                  attrs: { to: _vm.localizedRoute(link.route_link) }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm._f("htmlDecode")(link.name)) +
                      "\n    "
                  )
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.breadcrumbRoutes[index + 1]
            ? _c("div", { staticClass: "arrow mx7" })
            : _vm._e(),
          _vm._v(" "),
          _vm.isLastBreadcrumbs(index)
            ? _c("div", { staticClass: "pb1" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("htmlDecode")(link.name)) +
                    "\n    "
                )
              ])
            : _vm._e()
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }