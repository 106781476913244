import { Module } from 'vuex'
import actions from './actions'
import getters from './getters'
import RootState from '@vue-storefront/core/types/RootState'
import WishlistState from '@vue-storefront/core/modules/wishlist/types/WishlistState'

export const module: Module<WishlistState, RootState> = {
  actions,
  getters
}
