<template>
  <div>
    <a v-if="url"
       :href="url"
       target="_blank"
       rel="nofollow"
       class="no-underline"
    >
      <slot />
    </a>
    <router-link v-if="!url"
                 :to="localizedRoute(link)"
                 class="no-underline"
    >
      <slot />
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  }
}
</script>
