import config from 'config'

const SpecialEvent = () => import(/* webpackChunkName: "vsf-special-event" */ 'theme/pages/SpecialEvent.vue')
const GlasGoEvent = () => import(/* webpackChunkName: "vsf-glasgo-event" */ 'theme/components/theme/blocks/SpecialEvent/Events/glasGo.vue')
const MacallanNovikov = () => import(/* webpackChunkName: "vsf-macallan-novikov-event" */ 'theme/components/theme/blocks/SpecialEvent/Events/MacallanNovikov.vue')
const CourvoisierNovikovEvent = () => import(/* webpackChunkName: "vsf-courvoisier-novikov-event" */ 'theme/components/theme/blocks/SpecialEvent/Events/CourvoisierNovikovEvent.vue')

const availableSpecialEventCodesRegexpMask = () => {
  const eventCodesMask = Object.keys(config.products.events).join('|')
  return eventCodesMask ? `(${eventCodesMask})` : ''
}

export default [
  { name: 'glasgo-event', path: '/glasgo', component: GlasGoEvent, meta: { layout: 'flaer', title: 'Maxxium Russia' } },
  { name: 'macallan-novikov', path: '/macallan-nov', component: MacallanNovikov, meta: { layout: 'event', title: 'Maxxium Russia' } },
  { name: 'courvoisier-novikov', path: '/courvoisier-nov', component: CourvoisierNovikovEvent, meta: { layout: 'event', title: 'Maxxium Russia' } },
  { name: 'special-event', path: `/:eventCode${availableSpecialEventCodesRegexpMask()}/:filters(.*)?`, props: { slug: 'ves-katalog' }, component: SpecialEvent, meta: { layout: 'event', title: 'Maxxium Russia' } }
]
