import {GetterTree} from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import CategoryState from '../../types/CategoryState'

const getters: GetterTree<CategoryState, RootState> = {
  categoriesAfterFilterAndSort (state) {
    return [...state.list].filter((op) => {
      return (op.level === 1 || op.level === 2) && op.include_in_menu // display only the root level (level =1 => Default Category)
    }).sort((x, y) => {
      return x.top_menu_sort - y.top_menu_sort
    })
  }
}

export default getters
