export function beforeRegistration ({ Vue }) {
  if (!Vue.prototype.$isServer) {
    // Load the MindBox elements script.
    let docHeadForMB = document.getElementsByTagName('head')[0]
    let docScriptForMB = document.createElement('script')
    let ScriptForMB = document.createElement('script')

    ScriptForMB.async = true
    ScriptForMB.src = 'https://api.mindbox.ru/scripts/v1/tracker.js'

    const headScript = document.createTextNode(`
    mindbox = window.mindbox || function() { mindbox.queue.push(arguments); };
    mindbox.queue = mindbox.queue || [];
    mindbox('create');
    `)

    docScriptForMB.appendChild(headScript)
    docHeadForMB.appendChild(docScriptForMB)
    docHeadForMB.appendChild(ScriptForMB)
  }
}
