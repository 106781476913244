import actions from './actions'
import mutations from './mutations'

const state = {
  items: [],
  lastItems: [],
  total: 0,
  current: {},
  info: ''
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
