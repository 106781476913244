function isHasKeysObj (object) {
  return object && Object.keys(object).length > 0
}

function isHasKeyObj (object, key) {
  if (isHasKeysObj(object)) {
    for (let k in object) {
      if (k === key) return true
    }
    return false
  } else {
    return false
  }
}

/**
 * Метод позволяет узнать существует ли значение по заданному пути в исходном объекте
 * hasValue(this.product, ['values', 'is-ticker_boolean', '<all_channels>', '<all_locales>'])
 * @param {Object} object исходный объект
 * @param {Array} path массив строк(путь до цели)
 * @returns {Boolean} возвращает булево значение
 */
function hasValue (object, path = []) {
  if (isHasKeysObj(object) && Array.isArray(path)) {
    for (let key of path) {
      if (isHasKeyObj(object, key)) {
        object = object[key]
      } else {
        return false
      }
    }
    return !!object
  } else {
    return false
  }
}

export {
  hasValue
}
