<template>
  <li class="lh22 fs14 mb10">
    <span class="cl-gray-shadowed">{{ label|htmlDecode }}:</span>
    <span class="cl-gray">
      <router-link v-if="linkForCategory"
                   :to="linkForCategory"
                   class="cl-black underline"
      >{{ value|htmlDecode }}</router-link>
      <span v-else class="cl-black">{{ value|htmlDecode }}</span>
      <span v-if="attribute.attribute_code === 'bq_4748'" class="cl-black"> C</span>
    </span>
  </li>
</template>

<script>
import { ProductAttribute } from '@vue-storefront/core/modules/catalog/components/ProductAttribute.ts'

export default {
  mixins: [ProductAttribute]
}
</script>
