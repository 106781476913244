import * as types from './mutation-types'
import MetaState from '../types/MetaState'
import { MutationTree } from 'vuex'

const mutations: MutationTree<MetaState> = {
  [types.META_SET] (state, metaData) {
    state.data = metaData
  }
}
export default mutations
