<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import { mapState } from 'vuex'
import { isSameDomain, getLink } from 'theme/helpers/index.js'
import metaData from 'theme/mixins/metadata'

const DefaultLayout = () => import(/* webpackChunkName: "vsf-layout-default" */ './layouts/Default')
const EmptyLayout = () => import(/* webpackChunkName: "vsf-layout-empty" */ './layouts/Empty')
const MinimalLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Minimal')
const EventLayout = () => import(/* webpackChunkName: "vsf-layout-event" */ './layouts/Event')
const FlaerLayout = () => import(/* webpackChunkName: "vsf-layout-minimal" */ './layouts/Flaer')

export default {
  components: {
    DefaultLayout,
    EmptyLayout,
    MinimalLayout,
    EventLayout,
    FlaerLayout
  },
  mixins: [metaData],
  data () {
    return {
      ordersData: []
    }
  },
  computed: {
    ...mapState({
      overlayActive: state => state.ui.overlay
    }),
    layout () {
      return `${(this.$route.meta.layout || 'default')}-layout`
    }
  },
  serverPrefetch () {
    this.$store.dispatch('meta/loadMetaData', this.$route.fullPath) || this.$store.dispatch('meta/loadMetaData', this.$route.path)
  },
  beforeMount () {
    if (!this.$store.state.meta.data) {
      this.$store.dispatch('meta/loadMetaData', this.$route.path)
    }
    this.$router.beforeEach((to, from, next) => {
      if (to.path !== from.path) {
        this.$store.dispatch('meta/loadMetaData', to.path)
      }
      next()
    })
    this.$bus.$on('user-after-logout', this.trackUserLogout)
  },
  beforeDestroy () {
    this.$bus.$off('user-after-logout', this.trackUserLogout)
  },
  mounted () {
    window.addEventListener('click', event => {
      let isContinue = false
      let target = event.target
      let link = {}
      for (let i = 0; i <= 20; i++) {
        isContinue = target && target.classList && target.classList.contains('vhtml') ? true : isContinue
        link = target !== null && target.tagName === 'A' ? target : link
        if (target.parentNode) {
          target = target.parentNode
        } else {
          break
        }
      }
      if (isContinue) {
        if (link && link.href && isSameDomain(link.href) && link.target !== '_blank') {
          event.preventDefault()
          this.$router.push(getLink(link.href))
        }
      }
    })
    this.deviceUUIDCheck()
    document.onreadystatechange = () => {
      if (document.readyState === 'complete') {
        if (this.$store.state.user.current) {
          this.$store.dispatch('kt-gtm/userVisitedPage', this.$store.state.user.current.id)
        }
      }
    }
  },
  methods: {
    trackUserLogout () {
      if (typeof window !== 'undefined') {
        window.mindbox('async', {
          operation: 'UserExit',
          data: {
            customer: {
              ids: {
                maxxiumID: this.$store.state.ui.mindbox.mindboxUUID
              }
            }
          },
          onSuccess: function () {
          },
          onError: function (error) {
            console.error(error)
          }
        })
      }
    },
    deviceUUIDCheck () {
      if (window && window.location.href.includes('maxxium')) {
        const deviceUUIDInterval = setInterval(() => {
          const mindBoxUUID = this.$cookies.get('mindboxDeviceUUID') || localStorage.getItem('mindboxDeviceUUID')
          if (mindBoxUUID) {
            this.$store.commit('ui/mindbox/setUuid', mindBoxUUID)
            clearInterval(deviceUUIDInterval)
          }
        }, 500)
      }
    }
  }
}
</script>
