import uniqBy from 'lodash/uniqBy'
import { price } from '@vue-storefront/core/filters/price'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  computed: {
    currentStore () {
      return currentStoreView()
    },
    isCheckoutMode () {
      return parseInt(this.$store.state.config.shopSettings.checkoutMode) || this.isSpecialEvent
    },
    isSpecialEvent () {
      return this.$route.name === 'special-product'
    },
    isPriceHidden () {
      const currentProduct = this.currentChildren || this.product
      return !parseInt(currentProduct.price) || (currentProduct.show_price && !this.isVipUser)
    },
    isVipUser () {
      return this.$store.state.user.current ? this.$store.state.user.current.extension_attributes.vip : null
    },
    currentProductPrice () {
      const currentUserGroupId = this.$store.state.user.current ? this.$store.state.user.current.group_id : null
      if (!this.productVipPrice && currentUserGroupId) {
        return this.product.catalog_price_rule && this.product.catalog_price_rule[currentUserGroupId]
          ? price(this.product.catalog_price_rule[currentUserGroupId].price_with_discount)
          : price(this.product.price)
      }
      return price(this.product.price)
    },
    productVipPrice () {
      if (this.isVipCategory && this.product.catalog_price_rule) {
        const priceRules = this.product.catalog_price_rule
        const priceRulesKeys = Object.keys(this.product.catalog_price_rule)
        const vipPriceIndex = priceRulesKeys.find(ruleIndex => {
          return priceRules[ruleIndex] && priceRules[ruleIndex]['customerGroupName'] && priceRules[ruleIndex]['customerGroupName'] === 'Customer Vip'
        })
        if (vipPriceIndex) {
          const vipPrice = priceRules[vipPriceIndex]['price_with_discount']
          return price(vipPrice)
        }
      }
      return 0
    },
    currentProductName () {
      const productCategory = this.product.bq_4740 ? `${this.product.bq_4740} ` : ''
      return (this.currentChildren && this.currentChildren.custom_h_title) ? this.currentChildren.custom_h_title : `${productCategory}${this.product.name} ${this.currentChildrenBottleVolume}`
    },
    currentChildrenBottleVolume () {
      const configVolumes = this.$store.state.config.bottleValues
      const currentChosenVolume = this.currentChildren ? this.currentChildren.bottle_volume : ''
      return configVolumes[currentChosenVolume] ? `${configVolumes[currentChosenVolume]}л` : ''
    },
    thumbnail () {
      const image = this.product.image || this.product.thumbnail
      if (image) {
        return {
          src: this.getThumbnail(`/catalog/product${image}`, 600, 744),
          loading: this.getThumbnail(`/catalog/product${image}`, 80, 100),
          zoom: this.getThumbnail(`/catalog/product${image}`, 800, 1024)
        }
      }
      return null
    },
    thumbnailObj () {
      return {
        src: this.thumbnail ? this.thumbnail.src : '',
        loading: this.thumbnail ? this.thumbnail.loading : '',
        error: this.placeholder,
        zoom: this.thumbnail ? this.thumbnail.zoom : ''
      }
    },
    productGallery () {
      if (
        !this.product ||
        !this.product.media_gallery ||
        !this.product.media_gallery.length ||
        !this.currentChildren
      ) {
        return []
      }

      const getImageObj = (img) => {
        return {
          src: this.getThumbnail(`/catalog/product${img.image}`, 800, 800),
          loading: this.getThumbnail(`/catalog/product${img.image}`, 80, 100),
          error: this.placeholder,
          zoom: this.getThumbnail(`/catalog/product${img.image}`, 800, 800)
        }
      }
      const mediaGallery = this.product.media_gallery.slice()
      const mainImageIndex = mediaGallery.findIndex(item => {
        return item.types && item.types.includes('thumbnail')
      })
      const mainImage = mediaGallery.splice(mainImageIndex, 1)
      const sortedGallery = [...mainImage, ...mediaGallery]
      const gallery = sortedGallery.map(getImageObj)
      return uniqBy(gallery, item => item.src)
    },
    currentChildren () {
      if (this.product && this.product.configurable_children && this.product.configurable_children.length) {
        return this.product.configurable_children.find(children => children.url_key === this.$route.params.urlKey)
      }
      return null
    },
    offlineImage () {
      return {
        src: this.getThumbnail(this.product.image, 310, 300),
        error: this.getThumbnail(this.product.image, 310, 300),
        loading: this.getThumbnail(this.product.image, 310, 300)
      }
    }
  }
}
