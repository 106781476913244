import { MutationTree } from 'vuex'
import * as types from './mutation-types'
import CartState from '../types/CartState'

const mutations: MutationTree<CartState> = {
  [types.CART_UPD_CART_LOADER] (state, { cartIsLoading }) {
    state.cartIsLoading = cartIsLoading
  }
}

export default mutations
