import { VueStorefrontModule } from '@vue-storefront/core/lib/module'
import { beforeRegistration } from './hooks/beforeRegistration'
import { store } from './store'

const KEY = 'kt-clear-cache'

const moduleConfig = {
  key: KEY,
  store: { modules: [{ key: KEY, module: store }] },
  beforeRegistration
}

export const KtClearCache = new VueStorefrontModule(moduleConfig)
