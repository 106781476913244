import actions from './actions'
import mutations from './mutations'

const state = {
  currentCity: 'Москва',
  currentRegion: 'Московская обл.',
  currentCountry: 'RU'
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
