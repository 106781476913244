import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import UserState from '../types/UserState'

export const module: Module<UserState, RootState> = {
  namespaced: true,
  state: {
    token: '',
    refreshToken: '',
    orders_history: null,
    order: {
      current: null
    },
    hash: '',
    phone: '',
    confirmCode: '',
    ageConfirm: false
  },
  actions,
  mutations
}
