import { ActionTree } from 'vuex'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import CartState from '../types/CartState'
import * as localTypes from './mutation-types'
import * as types from '@vue-storefront/core/modules/cart/store/mutation-types'
import { Logger } from '@vue-storefront/core/lib/logger'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import Vue from 'vue'
import i18n from '@vue-storefront/core/i18n'
import Task from '@vue-storefront/core/lib/sync/types/Task'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import {currentStoreView} from '@vue-storefront/core/lib/multistore'
import config from 'config'

const MAX_BYPASS_COUNT = 10
let _connectBypassCount = 0

function _getDifflogPrototype () {
  return { items: [], serverResponses: [], clientNotifications: [] }
}

function _serverUpdateItem ({ cartServerToken, cartItem }): Promise<Task> {
  if (!cartItem.quoteId) {
    cartItem = Object.assign(cartItem, { quoteId: cartServerToken })
  }

  const cookieEnable = navigator && navigator.cookieEnabled
  const mindboxDeviceUUID = Vue.prototype.$cookies.get('mindboxDeviceUUID') || ''
  const mindboxDiviceUUIDFromLocalStorage = localStorage ? localStorage.getItem('mindboxDeviceUUID') : ''

  return TaskQueue.execute({ url: rootStore.state.config.cart.updateitem_endpoint, // sync the cart
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: cartItem,
        uuid: rootStore.state.ui.mindbox.mindboxUUID,
        cookieEnable,
        mindboxDiviceUUIDFromLocalStorage,
        mindboxDeviceUUID
      })
    }
  })
}

function _serverDeleteItem ({ cartServerToken, cartItem }): Promise<Task> {
  if (!cartItem.quoteId) {
    cartItem = Object.assign(cartItem, { quoteId: cartServerToken })
  }
  const cookieEnable = navigator && navigator.cookieEnabled
  const mindboxDeviceUUID = Vue.prototype.$cookies.get('mindboxDeviceUUID') || ''
  const mindboxDiviceUUIDFromLocalStorage = localStorage ? localStorage.getItem('mindboxDeviceUUID') : ''

  cartItem = Object.assign(cartItem, { quoteId: cartServerToken })
  return TaskQueue.execute({ url: rootStore.state.config.cart.deleteitem_endpoint, // sync the cart
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        cartItem: cartItem,
        uuid: rootStore.state.ui.mindbox.mindboxUUID,
        cookieEnable,
        mindboxDiviceUUIDFromLocalStorage,
        mindboxDeviceUUID
      })
    },
    silent: true
  })
}

async function _serverGetShippingMethods (address): Promise <Task> {
  const task = await TaskQueue.execute({ url: config.cart.shippingmethods_endpoint,
    payload: {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
      body: JSON.stringify({
        address: address
      })
    },
    silent: true
  })
  return task
}

const actions: ActionTree<CartState, RootState> = {
  async clear ({ commit, getters }, options = { recreateAndSyncCart: true }) {
    await commit(types.CART_LOAD_CART, [])
    if (options.recreateAndSyncCart && getters.isCartSyncEnabled) {
      await commit(types.CART_LOAD_CART_SERVER_TOKEN, null)
      await commit(types.CART_SET_ITEMS_HASH, null)
    }
  },
  async syncShippingMethods ({ getters, rootGetters, dispatch }, { forceServerSync = false }) {
    if (getters.isCartSyncEnabled && getters.isCartConnected && (getters.isTotalsSyncRequired || forceServerSync)) {
      const storeView = currentStoreView()
      Logger.debug('Refreshing shipping methods', 'cart')()
      let country = rootGetters['checkout/getShippingDetails'].country ? rootGetters['checkout/getShippingDetails'].country : storeView.tax.defaultCountry
      const shippingMethodsTask = await _serverGetShippingMethods({
        country_id: country
      })
      if (Array.isArray(shippingMethodsTask.result) && shippingMethodsTask.result.length > 0) {
        await dispatch('shipping/replaceMethods', shippingMethodsTask.result.map(method => Object.assign(method, { is_server_method: true })), { root: true })
      }
    } else {
      Logger.debug('Shipping methods does not need to be updated', 'cart')()
    }
  },
  updateQuantity ({ commit, dispatch }, { product, qty, forceServerSilence = false }) {
    commit(types.CART_UPD_ITEM, { product, qty })
    if (rootStore.state.config.cart.synchronize && product.server_item_id && !forceServerSilence) {
      commit(localTypes.CART_UPD_CART_LOADER, { cartIsLoading: true })
      return dispatch('serverPull', { forceClientState: true }).then(res => {
        dispatch('resetLoader')
        return res
      })
    }
  },
  async addItem ({ rootState, commit, dispatch }, { productToAdd, forceServerSilence = false }) {
    commit(localTypes.CART_UPD_CART_LOADER, { cartIsLoading: true })
    let productsToAdd = []
    if (productToAdd.type_id === 'grouped') { // TODO: add bundle support
      productsToAdd = productToAdd.product_links.filter((pl) => { return pl.link_type === 'associated' }).map((pl) => { return pl.product })
    } else {
      productsToAdd.push(productToAdd)
    }
    return dispatch('addItems', { productsToAdd: productsToAdd, forceServerSilence }).then(async (resp) => {
      dispatch('resetLoader')
      let serverResponse = resp && resp.serverResponses && resp.serverResponses[0] && resp.serverResponses[0]
      if (serverResponse && serverResponse.status !== 200) {
        throw new Error(resp.result.result)
      } else {
        const product = rootState.product.current
        if (product) {
          rootStore.dispatch('kt-gtm/additionalEvents', {'event': 'AddToCart', product})
          rootStore.dispatch('kt-gtm/addProductToCart', product)
        }
        await dispatch('syncForGift', {serverResponse: resp.serverResponses, productsToAdd})
      }
      return resp.serverResponses[0]
    })
  },
  async syncForGift (payload, {serverResponse = null, productsToAdd = null}) {
    const addProductSku = productsToAdd[0] && productsToAdd[0].sku
    const findProductWithServer = serverResponse.find(product => product.sku === addProductSku)
    const extension_attributes = findProductWithServer &&
      findProductWithServer.result &&
      findProductWithServer.result.result &&
      findProductWithServer.result.result.extension_attributes
    const hasGift = extension_attributes && extension_attributes.has_gift_product
    if (hasGift) {
      rootStore.dispatch('cart/sync', {syncForGift: hasGift}).then(() => {
        const giftSku = extension_attributes.gift_sku
        const giftQty = extension_attributes.gift_qty
        if (findProductWithServer.qty !== giftQty && giftSku) {
          const giftProduct = rootStore.state.cart.cartItems.find(product => product.sku === giftSku)
          if (giftProduct) rootStore.dispatch('cart/updateQuantity', { product: giftProduct, qty: giftQty })
        }
      })
    }
  },
  resetLoader ({ commit }) {
    commit(localTypes.CART_UPD_CART_LOADER, { cartIsLoading: false })
  },
  /** Sync the shopping cart with server along with totals (when needed) and shipping / payment methods */
  async sync ({ getters, rootGetters, commit, dispatch }, { forceClientState = false, dryRun = false, syncForGift = false }) { // pull current cart FROM the server
    const isUserInCheckout = rootGetters['checkout/isUserInCheckout']
    let diffLog = _getDifflogPrototype()
    if (isUserInCheckout) forceClientState = true // never surprise the user in checkout - #
    if (getters.isCartSyncEnabled && getters.isCartConnected) {
      if (getters.isSyncRequired || syncForGift) { // cart hash empty or not changed
        /** @todo: move this call to data resolver; shouldn't be a part of public API no more */
        commit(types.CART_SET_SYNC)
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const task = await TaskQueue.execute({ url: config.cart.pull_endpoint, // sync the cart
          payload: {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
          },
          silent: true
        }).then(async task => {
          if (task.resultCode === 200) {
            diffLog = await dispatch('merge', { serverItems: task.result, clientItems: getters.getCartItems, dryRun: dryRun, forceClientState: forceClientState })
          } else {
            Logger.error(task.result, 'cart') // override with guest cart()
            if (_connectBypassCount < MAX_BYPASS_COUNT) {
              Logger.log('Bypassing with guest cart' + _connectBypassCount, 'cart')()
              _connectBypassCount = _connectBypassCount + 1
              await dispatch('connect', { guestCart: true })
              Logger.error(task.result, 'cart')()
            }
          }
        })
        return diffLog
      } else {
        return diffLog
      }
    } else {
      return diffLog
    }
  },
  async merge ({ getters, dispatch, commit, rootGetters }, { serverItems, clientItems, dryRun = false, forceClientState = false }) {
    const diffLog = _getDifflogPrototype()
    let serverCartUpdateRequired = false
    let clientCartUpdateRequired = false
    const clientCartAddItems = []

    /** helper to find the item to be added to the cart by sku */
    let productActionOptions = (serverItem) => {
      return new Promise(resolve => {
        if (serverItem.product_type === 'configurable') {
          let searchQuery = new SearchQuery()
          searchQuery = searchQuery.applyFilter({key: 'configurable_children.sku', value: {'eq': serverItem.sku}})
          dispatch('product/list', {query: searchQuery, start: 0, size: 1, updateState: false}, { root: true }).then((resp) => {
            if (resp.items.length >= 1) {
              resolve({ sku: resp.items[0].sku, childSku: serverItem.sku })
            }
          })
        } else {
          resolve({ sku: serverItem.sku })
        }
      })
    }
    /** helper - sub method to update the item in the cart */
    const _updateClientItem = async function ({ dispatch }, event, clientItem) {
      if (typeof event.result.item_id !== 'undefined') {
        await dispatch('updateItem', { product: { server_item_id: event.result.item_id, sku: clientItem.sku, server_cart_id: event.result.quote_id, prev_qty: clientItem.qty } }) // update the server_id reference
        Vue.prototype.$bus.$emit('cart-after-itemchanged', { item: clientItem })
      }
    }

    /** helper - sub method to react for the server response after the sync */
    const _afterServerItemUpdated = async function ({ dispatch, commit }, event, clientItem = null) {
      Logger.debug('Cart item server sync' + event, 'cart')()
      diffLog.serverResponses.push({ 'status': event.resultCode, 'sku': clientItem.sku, 'result': event })
      if (event.resultCode !== 200) {
        // TODO: add the strategy to configure behaviour if the product is (confirmed) out of the stock
        if (clientItem.server_item_id) {
          dispatch('getItem', clientItem.sku).then((cartItem) => {
            if (cartItem) {
              Logger.log('Restoring qty after error' + clientItem.sku + cartItem.prev_qty, 'cart')()
              if (cartItem.prev_qty > 0) {
                cartItem.qty = cartItem.prev_qty
                dispatch('updateItem', { product: cartItem }) // update the server_id reference
                Vue.prototype.$bus.$emit('cart-after-itemchanged', { item: cartItem })
              } else {
                dispatch('removeItem', { product: cartItem, removeByParentSku: false }) // update the server_id reference
              }
            }
          })
        } else {
          Logger.warn('Removing product from cart', 'cart', clientItem)()
          commit(types.CART_DEL_NON_CONFIRMED_ITEM, { product: clientItem })
        }
      } else {
        const isUserInCheckout = rootGetters['checkout/isUserInCheckout']
        if (!isUserInCheckout) { // if user is in the checkout - this callback is just a result of server sync
          const isThisNewItemAddedToTheCart = (!clientItem || !clientItem.server_item_id)
          const notificationData = {
            type: 'success',
            message: isThisNewItemAddedToTheCart ? i18n.t('Product has been added to the cart!') : i18n.t('Product quantity has been updated!'),
            action1: { label: i18n.t('OK') },
            action2: null
          }
          if (!rootStore.state.config.externalCheckout) { // if there is externalCheckout enabled we don't offer action to go to checkout as it can generate cart desync
            notificationData.action2 = { label: i18n.t('Proceed to checkout'),
              action: () => {
                dispatch('goToCheckout')
              }}
          }
          diffLog.clientNotifications.push(notificationData) // display the notification only for newly added products
        }
      }
      if (clientItem === null) {
        const cartItem = await dispatch('getItem', event.result.sku)
        if (cartItem) {
          await _updateClientItem({ dispatch }, event, cartItem)
        }
      } else {
        await _updateClientItem({ dispatch }, event, clientItem)
      }
    }
    for (const clientItem of clientItems) {
      const serverItem = serverItems.find((itm) => {
        return itm.sku === clientItem.sku || itm.sku.indexOf(clientItem.sku + '-') === 0 /* bundle products */
      })

      if (!serverItem) {
        Logger.warn('No server item with sku ' + clientItem.sku + ' on stock.', 'cart')()
        diffLog.items.push({ 'party': 'server', 'sku': clientItem.sku, 'status': 'no-item' })
        if (!dryRun) {
          if (forceClientState || !rootStore.state.config.cart.serverSyncCanRemoveLocalItems) {
            const event = await _serverUpdateItem({
              cartServerToken: getters.getCartToken,
              cartItem: {
                sku: clientItem.parentSku && rootStore.state.config.cart.setConfigurableProductOptions ? clientItem.parentSku : clientItem.sku,
                qty: clientItem.qty,
                product_option: clientItem.product_option
              }
            })
            _afterServerItemUpdated({ dispatch, commit }, event, clientItem)
            serverCartUpdateRequired = true
          } else {
            dispatch('removeItem', {
              product: clientItem
            })
          }
        }
      } else if (serverItem.qty !== clientItem.qty) {
        Logger.log('Wrong qty for ' + clientItem.sku, clientItem.qty, serverItem.qty)()
        diffLog.items.push({ 'party': 'server', 'sku': clientItem.sku, 'status': 'wrong-qty', 'client-qty': clientItem.qty, 'server-qty': serverItem.qty })
        if (!dryRun) {
          if (forceClientState || !rootStore.state.config.cart.serverSyncCanModifyLocalItems) {
            const event = await _serverUpdateItem({
              cartServerToken: getters.getCartToken,
              cartItem: {
                sku: clientItem.parentSku && rootStore.state.config.cart.setConfigurableProductOptions ? clientItem.parentSku : clientItem.sku,
                qty: clientItem.qty,
                item_id: serverItem.item_id,
                quoteId: serverItem.quote_id,
                product_option: clientItem.product_option
              }
            })
            _afterServerItemUpdated({ dispatch, commit }, event, clientItem)
            serverCartUpdateRequired = true
          } else {
            await dispatch('updateItem', {
              product: serverItem
            })
          }
        }
      } else {
        Logger.info('Server and client item with SKU ' + clientItem.sku + ' synced. Updating cart.', 'cart', 'cart')()
        if (!dryRun) {
          await dispatch('updateItem', { product: { sku: clientItem.sku, server_cart_id: serverItem.quote_id, server_item_id: serverItem.item_id, product_option: serverItem.product_option } })
        }
      }
    }

    for (const serverItem of serverItems) {
      if (serverItem) {
        const clientItem = clientItems.find((itm) => {
          return itm.sku === serverItem.sku || serverItem.sku.indexOf(itm.sku + '-') === 0 /* bundle products */
        })
        if (!clientItem) {
          Logger.info('No client item for' + serverItem.sku, 'cart')()
          diffLog.items.push({ 'party': 'client', 'sku': serverItem.sku, 'status': 'no-item' })

          if (!dryRun) {
            if (forceClientState) {
              Logger.info('Removing product from cart', 'cart', serverItem)()
              Logger.log('Removing item' + serverItem.sku + serverItem.item_id, 'cart')()
              serverCartUpdateRequired = true
              const res = await _serverDeleteItem({
                cartServerToken: getters.getCartToken,
                cartItem: {
                  sku: serverItem.sku,
                  item_id: serverItem.item_id,
                  quoteId: serverItem.quote_id
                }
              })
              diffLog.serverResponses.push({ 'status': res.resultCode, 'sku': serverItem.sku, 'result': res })
            } else {
              clientCartAddItems.push(
                new Promise(resolve => {
                  productActionOptions(serverItem).then((actionOtions) => {
                    dispatch('product/single', { options: actionOtions, assignDefaultVariant: true, setCurrentProduct: false, selectDefaultVariant: false }, { root: true }).then((product) => {
                      resolve({ product: product, serverItem: serverItem })
                    })
                  })
                })
              )
            }
          }
        }
      }
    }
    if (clientCartAddItems.length) {
      clientCartUpdateRequired = true
    }
    diffLog.items.push({ 'party': 'client', 'status': clientCartUpdateRequired ? 'update-required' : 'no-changes' })
    diffLog.items.push({ 'party': 'server', 'status': serverCartUpdateRequired ? 'update-required' : 'no-changes' })
    Promise.all(clientCartAddItems).then((items) => {
      items.map(({ product, serverItem }) => {
        product.server_item_id = serverItem.item_id
        product.qty = serverItem.qty
        product.server_cart_id = serverItem.quote_id
        if (serverItem.product_option) {
          product.product_option = serverItem.product_option
        }
        dispatch('addItem', { productToAdd: product, forceServerSilence: true })
      })
    })
    if (!dryRun && diffLog && diffLog.serverResponses && diffLog.serverResponses[0] && diffLog.serverResponses[0].status === 200) {
      commit(types.CART_SET_ITEMS_HASH, getters.getCurrentCartHash) // update the cart hash
    }
    Vue.prototype.$bus.$emit('servercart-after-diff', { diffLog: diffLog, serverItems: serverItems, clientItems: clientItems, dryRun: dryRun, event: event }) // send the difflog
    Logger.info('Client/Server cart synchronised ', 'cart', diffLog)()
    return diffLog
  }
}

export default actions
