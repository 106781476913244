import { Module } from 'vuex'
import actions from './actions'
import mutations from './mutations'
import RootState from '@vue-storefront/core/types/RootState'
import TaxState from '@vue-storefront/core/modules/catalog/types/TaxState'

export const taxModule: Module<TaxState, RootState> = {
  actions,
  mutations
}
