import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import builder from 'bodybuilder'

const actions = {
  list (context, {query = '', start = 0, size = 16, entityType = 'blogpost', setList = true, updateList = true}) {
    if (!query) {
      query = builder().query('range', 'publish_date', {lte: 'now'}).filter('term', 'enabled', '1').sort('publish_date', 'desc')
    }
    return quickSearchByQuery({ query: query.build(), entityType: entityType, start: start, size: size }).then((resp) => {
      if (setList) {
        context.commit('NEWS_SET_POSTS', { items: resp.items, updateList: updateList })
        context.commit('NEWS_SET_TOTAL', resp.total)
      }
      return resp
    })
  },
  loadLastNews (context, {query = '', start = 0, size = 4}) {
    if (!query) {
      query = builder().query('range', 'publish_date', {lte: 'now'}).filter('term', 'enabled', '1').sort('publish_date', 'desc')
    }
    context.dispatch('list', {query: query, start: start, size: size, setList: false}).then(resp => {
      context.commit('NEWS_SET_LAST_ITEMS', resp.items)
      return resp
    })
  },
  single (context, { key = '', value = '', setCurrent = true }) {
    let searchQuery = builder().query('term', `${key}`, `${value}`)
    return new Promise((resolve) => {
      if (context.state.items && context.state.items.length) {
        let currentItem = context.state.items.find(item => item[key] === value)
        if (setCurrent && currentItem) {
          context.commit('NEWS_SET_CURRENT_POST', currentItem)
        } else {
          context.dispatch('list', { query: searchQuery, size: 1, setList: false }).then(resp => {
            if (setCurrent && resp.items && resp.items.length) {
              context.commit('NEWS_SET_CURRENT_POST', resp.items[0])
            }
          })
        }
        resolve()
      } else {
        context.dispatch('list', { query: searchQuery, size: 1, setList: false }).then(resp => {
          if (setCurrent && resp.items && resp.items.length) {
            context.commit('NEWS_SET_CURRENT_POST', resp.items[0])
          }
          resolve()
        })
      }
    })
  },
  getTextForInfoBlock (context) {
    let query = builder().query('terms', 'path.keyword', ['general/store_information/add_info']).build()
    quickSearchByQuery({ query, entityType: 'core_config', size: 1 }).then((resp) => {
      const infoText = resp && resp.items && resp.items.length ? resp.items[0].value : ''
      context.commit('NEWS_SET_INFO', infoText)
    })
  }
}

export default actions
