import Vue from 'vue'
import * as types from '@vue-storefront/core/modules/order/store/mutation-types'
import { ValidationError } from '@vue-storefront/core/store/lib/exceptions'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import { ActionTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import OrderState from '../types/OrderState'
import rootStore from '@vue-storefront/core/store'
import { isOnline } from '@vue-storefront/core/lib/search'
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import fetch from 'isomorphic-fetch'
import * as localTypes from './mutation-types'

const Ajv = require('ajv')
const actions: ActionTree<OrderState, RootState> = {
  async getMinimalOrder ({commit}) {
    let url = rootStore.state.config.orders.endpointMinimalOrder
    console.log(url)
    await fetch(url, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors'
    }).then(res => res.json()).then(res => {
      if (res.code === 200 && res.result && typeof res.result === 'number') {
        commit(localTypes.SET_MINIMAL_ORDER, res.result)
      }
    })
  },
  /**
   * Place order - send it to service worker queue
   * @param {Object} commit method
   * @param {Object} order order data to be send
   */
  placeOrder ({ commit }, order) {
    const ajv = new Ajv()
    const orderSchema = require('./order.schema.json')
    const orderSchemaExtension = require('./order.schema.extension.json')
    const validate = ajv.compile(Object.assign(orderSchema, orderSchemaExtension))

    const storeView = currentStoreView()
    if (storeView.storeCode) {
      order.store_code = storeView.storeCode
    }

    if (!validate(order)) { // schema validation of upcoming order
      throw new ValidationError(validate.errors)
    } else {
      Vue.prototype.$bus.$emit('order-before-placed', { order: order })
      if (!rootStore.state.config.orders.directBackendSync || !isOnline()) {
        commit(types.ORDER_PLACE_ORDER, order)
        Vue.prototype.$bus.$emit('order-after-placed', { order: order })
        return Promise.resolve(true)
      } else {
        Vue.prototype.$bus.$emit('notification-progress-start', 'Ваша заявка отправляется')
        order.uuid = rootStore.state.ui.mindbox.mindboxUUID
        return TaskQueue.execute({ url: rootStore.state.config.orders.endpoint, // sync the order
          payload: {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${rootStore.state.user.token}` },
            mode: 'cors',
            body: JSON.stringify(order)
          }
        }).then((task: any) => {
          Vue.prototype.$bus.$emit('notification-progress-stop')
          if (task.resultCode !== 500) {
            order.transmited = true
            commit(types.ORDER_PLACE_ORDER, order) // archive this order but not trasmit it second time
            commit(types.ORDER_LAST_ORDER_WITH_CONFIRMATION, { order: order, confirmation: task.result })
            Vue.prototype.$bus.$emit('order-after-placed', { order: order, confirmation: task.result })
          }
          return task
        }).catch(e => {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'error',
            message: 'Ваша заявка не отправлена',
            action1: { label: 'OK' }
          })
          order.transmited = false // queue order
          commit(types.ORDER_PLACE_ORDER, order) // archive this order but not trasmit it second time
          Vue.prototype.$bus.$emit('notification-progress-stop')
          throw (e)
        })
      }
    }
  }
}

export default actions
