<template>
  <div />
</template>

<script>
export default {
  data () {
    return {
      scrollY: 0,
      windowHeight: 0,
      windowWidth: 0,
      documentWidth: 0
    }
  },
  beforeMount () {
    this.$nextTick(function () {
      window.addEventListener('resize', this.getParams)
      window.addEventListener('scroll', this.getScrollY)
      this.getScrollY()
      this.getParams()
    })
    this.getScrollY()
    this.getParams()
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.getScrollY)
    window.removeEventListener('resize', this.getParams)
  },
  methods: {
    getWindowHeight () {
      this.windowHeight = window.innerHeight
    },
    getScrollY () {
      this.scrollY = window.pageYOffset
    },
    getWindowWidth () {
      this.windowWidth = window.innerWidth
    },
    getDocumentWidth () {
      this.documentWidth = document.documentElement.clientWidth
    },
    getParams (event) {
      this.getWindowHeight()
      this.getWindowWidth(event)
      this.getDocumentWidth(event)
    }
  }
}
</script>
