import { connect as websocket } from '../lib/websocket'
import { checkVersion } from '../lib/versioning'

export async function beforeRegistration ({ config, store, isServer }) {
  if (!isServer) {
    if (config.clearCache && config.clearCache.enabled) {
      if (config.clearCache.websocket.enabled) {
        websocket(store)
      }
      if (config.clearCache.version.enabled) {
        checkVersion()
      }
    }
  }
}
