export function beforeRegistration ({ Vue, config }) {
  if (!Vue.prototype.$isServer) {
    const loadGTM = function () {
      const timer = setTimeout(() => {
        // Load the GTM elements script.
        let docHead = document.getElementsByTagName('head')[0]
        let docBody = document.getElementsByTagName('body')[0]

        let docScript = document.createElement('script')
        let noScript = document.createElement('noscript')
        let iframe = document.createElement('iframe')

        iframe.src = `https://www.googletagmanager.com/ns.html?id=${config.GTM.id}`
        iframe.style.display = 'none'
        iframe.style.visibility = 'hidden'
        iframe.height = '0'
        iframe.width = '0'

        const headScript = document.createTextNode(`<!-- Google Tag Manager -->
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${config.GTM.id}');
        <!-- End Google Tag Manager -->`)

        docScript.appendChild(headScript)
        noScript.appendChild(iframe)
        docHead.appendChild(docScript)
        docBody.insertBefore(noScript, document.body.firstChild)
        clearTimeout(timer)
      }, 3000)
    }
    window.addEventListener('load', loadGTM, false)
  }
}
