var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 h-100" },
    [
      [
        _c("pre", [_vm._v(_vm._s(_vm.banner.image))]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "lazy",
                rawName: "v-lazy:background-image",
                value: _vm.getThumbnail("/" + _vm.banner.image, 325, 478),
                expression: "getThumbnail(`/${banner.image}`, 325, 478)",
                arg: "background-image"
              }
            ],
            staticClass: "banner-wrapper w-100 h-100 border-box",
            style: [{ color: _vm.getTextColor }]
          },
          [
            _c(
              "div",
              { staticClass: "content-wrapper col-xs-12 flex between-xs" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm.banner.title
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-100 banner-title bebas lh55 fs36 ls3 uppercase mb20",
                          class: { "big-text": !_vm.showSubtitle }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.banner.title) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSubtitle
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "w-100 banner-subtitle serif lh22 fs14 pb26"
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.banner.subtitle) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.banner.button_text
                  ? [
                      _c(
                        "navigation-link",
                        {
                          staticClass:
                            "banner-button border-box weight-600 fs12 lh14 flex center-xs middle-xs ls4 uppercase pointer",
                          style: {
                            color: _vm.banner.text_color,
                            borderColor: _vm.banner.btn_color
                          },
                          attrs: {
                            url: _vm.getUrl(_vm.banner, "click_url"),
                            link: _vm.getUrl(_vm.banner, "link")
                          }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.banner.button_text) +
                              "\n          "
                          )
                        ]
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        )
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }