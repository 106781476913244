var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "label-block relative" }, [
    _c(
      "div",
      {
        staticClass: "w-100 text uppercase fs12 lh18 text-label bebas",
        style: { color: _vm.checkColorText }
      },
      [_vm._v("\n    " + _vm._s(_vm.text) + "\n  ")]
    ),
    _vm._v(" "),
    _c(
      "svg",
      {
        attrs: {
          width: "109",
          height: "31",
          viewBox: "0 0 109 31",
          fill: "none",
          xmlns: "http://www.w3.org/2000/svg"
        }
      },
      [
        _c("path", {
          attrs: {
            fill: _vm.checkColorBg,
            d:
              "M109 -6.67189e-06L102 15L109 31L0 31L-1.35505e-06 1.71661e-05L109 -6.67189e-06Z"
          }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }