import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from './mutation-types'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import RootState from '@vue-storefront/core/types/RootState'
import SliderModuleState from '../types/sliderModuleState'

function quickSearchSlider (context, query, excludeFields, includeFields) {
  return quickSearchByQuery({ size: 1000, query, entityType: 'banner', sort: 'id', excludeFields, includeFields })
    .then((resp) => {
      context.commit(types.ADD_SLIDER, resp.items)
      return resp.items
    })
    .catch(err => {
      console.error(err)
    })
}

const actions: ActionTree<SliderModuleState, RootState> = {
  /**
   * Retrieve Sliders
   *
   * @param context
   * @param {any} filterValues
   * @param {any} filterField
   * @param {any} size
   * @param {any} start
   * @param {any} excludeFields
   * @param {any} includeFields
   * @returns {Promise<T> & Promise<any>}
   */
  list (context, { filterValues = null, filterField = 'slider_identifier.keyword', excludeFields = null, includeFields = null }) {
    let query = new SearchQuery()
    if (filterValues) {
      query = query.applyFilter({key: filterField, value: {'like': filterValues}}).applyFilter({key: 'status', value: {'like': 1}})
    }
    return quickSearchByQuery({ query, entityType: 'banner', sort: 'id', excludeFields, includeFields })
      .then((resp) => {
        context.commit(types.ADD_SLIDER, resp.items)
        return resp.items
      })
      .catch(err => {
        console.error(err)
      })
  },

  /**
   * Retrieve single Slider by key value
   *
   * @param context
   * @param {any} key
   * @param {any} value
   * @param {any} excludeFields
   * @param {any} includeFields
   * @returns {Promise<T> & Promise<any>}
   */
  async single (context, { key = 'slider_identifier.keyword', value, excludeFields = null, includeFields = null, skipCache = false }) {
    const state = context.state
    let query = new SearchQuery()
    if (value) {
      query = query.applyFilter({key: key, value: {'like': value}}).applyFilter({key: 'status', value: {'like': 1}})
    }
    if (skipCache || (!state.items || !state.items.find(c => c[key] === value))) {
      await quickSearchSlider(context, query, excludeFields, includeFields)
    } else {
      return new Promise((resolve, reject) => {
        if (state.items.length > 0) {
          let slider = state.items.find((itm) => { return itm[key] === value })
          if (slider) {
            quickSearchSlider(context, query, excludeFields, includeFields)
            resolve(slider)
          } else {
            reject(new Error('Slider query returned empty result ' + key + ' = ' + value))
          }
        } else {
          resolve({})
        }
      })
    }
  }
}

export default actions
