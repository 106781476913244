var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.url
        ? _c(
            "a",
            {
              staticClass: "no-underline",
              attrs: { href: _vm.url, target: "_blank", rel: "nofollow" }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.url
        ? _c(
            "router-link",
            {
              staticClass: "no-underline",
              attrs: { to: _vm.localizedRoute(_vm.link) }
            },
            [_vm._t("default")],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }