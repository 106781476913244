import { ActionTree } from 'vuex'
import * as types from '@vue-storefront/core/modules/wishlist/store/mutation-types'
import i18n from '@vue-storefront/i18n'
import { htmlDecode } from '@vue-storefront/core/store/lib/filters'
import rootStore from '@vue-storefront/core/store'
import RootState from '@vue-storefront/core/types/RootState'
import WishlistState from '@vue-storefront/core/modules/wishlist/types/WishlistState'
import { cacheStorage } from '../'
import { Logger } from '@vue-storefront/core/lib/logger'
import Vue from 'vue'
import uniqBy from 'lodash-es/uniqBy'
import differenceBy from 'lodash-es/differenceBy'
import { slugify } from '@vue-storefront/core/helpers'

const updateWishlist = (productsIds, msg = null) => {
  const token = rootStore.state.user.token
  let data = {
    uuid: rootStore.state.ui.mindbox.mindboxUUID,
    productsIds,
    authorized: Boolean(token)
  }

  if (token) {
    Object.assign(data, { token })
  }
  return fetch(`${rootStore.state.config.wishlist.urlUpdate}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    mode: 'cors',
    body: JSON.stringify(data)
  }).then(resp => resp.json())
    .then(({ code, result } = {}) => {
      if (code === 200 && result && msg) {
        Logger.info(msg)
      }
    }).catch(err => {
      console.error(err)
    })
}

const handleLoadWishlist = (localWishList, loadedWishList) => {
  const wishlistDifference = differenceBy(localWishList, loadedWishList, 'id')
  const modifiedWishList = loadedWishList.map(product => {
    if (product.url_key && rootStore.state.config.products.useMagentoUrlKeys) {
      product.slug = product.url_key
    } else if (product.name) {
      product.slug = `${slugify(product.name)}-${product.id}`
    }
    return product
  })
  if (localWishList) {
    modifiedWishList.concat(localWishList)
  }
  const mergedWishlist = uniqBy(modifiedWishList, 'id')

  if (wishlistDifference.length) {
    updateWishlist(mergedWishlist.map(product => product.id))
  }
  return mergedWishlist
}

const actions: ActionTree<WishlistState, RootState> = {
  clear ({ commit }) {
    commit(types.WISH_LOAD_WISH, [])
  },
  load (context) {
    Vue.prototype.$db.usersCollection.getItem('current-token', (err, currentToken) => {
      if (err) throw new Error(err)
      return currentToken
    }).then((token) => {
      cacheStorage.getItem('current-wishlist', (err, storedItems) => {
        if (err) throw new Error(err)
        if (token) {
          fetch(`${rootStore.state.config.wishlist.urlLoad}?customerToken=${token}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            mode: 'cors'
          }).then(resp => resp.json())
            .then(({ code, result } = {}) => {
              return code === 200 && result && Array.isArray(result)
                ? result
                : []
            })
            .then((loadedWishlist) => handleLoadWishlist(storedItems, loadedWishlist))
            .then((mergedWishlist) => { context.commit(types.WISH_LOAD_WISH, mergedWishlist) })
        } else {
          context.commit(types.WISH_LOAD_WISH, storedItems)
        }
        Logger.info('Wishlist state loaded from browser cache. ', 'cache', storedItems)()
      })
    })
  },
  addItem ({ commit, getters }, product) {
    const notificationMsg = i18n.t('Product {productName} has been added to wishlist!', { productName: htmlDecode(product.name) })
    commit(types.WISH_ADD_ITEM, { product })
    return updateWishlist(getters.wishlistIds, notificationMsg)
  },
  removeItem ({ commit, getters }, product) {
    const notificationMsg = i18n.t('Product {productName} has been removed from wishlist!', { productName: htmlDecode(product.name) })
    commit(types.WISH_DEL_ITEM, { product })
    return updateWishlist(getters.wishlistIds, notificationMsg)
  }
}

export default actions
