var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "lh22 fs14 mb10" }, [
    _c("span", { staticClass: "cl-gray-shadowed" }, [
      _vm._v(_vm._s(_vm._f("htmlDecode")(_vm.label)) + ":")
    ]),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "cl-gray" },
      [
        _vm.linkForCategory
          ? _c(
              "router-link",
              {
                staticClass: "cl-black underline",
                attrs: { to: _vm.linkForCategory }
              },
              [_vm._v(_vm._s(_vm._f("htmlDecode")(_vm.value)))]
            )
          : _c("span", { staticClass: "cl-black" }, [
              _vm._v(_vm._s(_vm._f("htmlDecode")(_vm.value)))
            ]),
        _vm._v(" "),
        _vm.attribute.attribute_code === "bq_4748"
          ? _c("span", { staticClass: "cl-black" }, [_vm._v(" C")])
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }