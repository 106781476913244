import { ActionTree } from 'vuex'
import { quickSearchByQuery } from '@vue-storefront/core/lib/search'
import * as types from '@vue-storefront/core/modules/cms/store/block/mutation-types'
import SearchQuery from '@vue-storefront/core/lib/search/searchQuery'
import RootState from '@vue-storefront/core/types/RootState'
import CmsBlockState from '@vue-storefront/core/modules/cms/types/CmsBlockState'

function quickSearchCmsBlock (context, query, excludeFields, includeFields) {
  return quickSearchByQuery({ query, entityType: 'cms_block', excludeFields, includeFields })
    .then((resp) => {
      if (resp.items[0]) {
        context.commit(types.CMS_BLOCK_ADD_CMS_BLOCK, resp.items[0])
        return resp.items[0]
      }
      return null
    })
    .catch(err => {
      console.error(err)
    })
}

const actions: ActionTree<CmsBlockState, RootState> = {
  /**
   * Retrieve single cms block by key value
   *
   * @param context
   * @param {any} key
   * @param {any} value
   * @param {any} excludeFields
   * @param {any} includeFields
   * @returns {Promise<T> & Promise<any>}
   */
  single (context, { key = 'identifier', value, excludeFields = null, includeFields = null, skipCache = false }) {
    const state = context.state
    let query = new SearchQuery()
    if (value) {
      query = query.applyFilter({key: key, value: {'like': value}})
    }
    if (skipCache || (!state.items || !state.items.find(c => c.identifier === value))) {
      return quickSearchCmsBlock(context, query, excludeFields, includeFields)
    } else {
      return new Promise<void>((resolve, reject) => {
        if (state.items.length > 0) {
          let cmsBlock = state.items.find((itm) => { return itm[key] === value })
          if (cmsBlock) {
            quickSearchCmsBlock(context, query, excludeFields, includeFields)
            resolve(cmsBlock)
          } else {
            reject(new Error('CMS block query returned empty result ' + key + ' = ' + value))
          }
        } else {
          resolve()
        }
      })
    }
  }
}

export default actions
