import { Module } from 'vuex'
import mutations from './mutations'
import MindboxState from '../../types/MindboxState'

export const mindbox: Module<MindboxState, any> = {
  namespaced: true,
  state: {
    mindboxUUID: ''
  },
  mutations
}
