import { beforeRegistration } from './hooks/beforeRegistration'
import { createModule } from '@vue-storefront/core/lib/module'
import { store } from './store'

declare global {
  interface Window {
    dataLayer: any
  }
}

export const KEY = 'kt-gtm'

export const KtGtm = createModule({
  key: KEY,
  store: {
    modules: [{ key: KEY, module: store }]
  },
  beforeRegistration
})
