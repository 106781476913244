var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "router-link",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: _vm.visibilityChanged,
          expression: "visibilityChanged"
        }
      ],
      staticClass:
        "product flex bg-cl-primary align-center w-100 h-100 px12 py40 border-box relative pointer no-underline",
      attrs: { to: _vm.productLink },
      nativeOn: {
        click: function($event) {
          return _vm.closeMicrocart($event)
        }
      }
    },
    [
      _vm.product.name_label
        ? _c("product-label", {
            attrs: {
              "bg-color": _vm.product.background_label,
              "text-color": _vm.product.color_label,
              text: _vm.product.name_label
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "label-image relative" }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "product-container w-100 h-100 flex center-xs pb20",
          attrs: { "data-testid": "productLink" }
        },
        [
          _c("div", { staticClass: "w-100 product-image relative flex" }, [
            _c("img", {
              directives: [
                {
                  name: "lazy",
                  rawName: "v-lazy",
                  value: _vm.thumbnail,
                  expression: "thumbnail"
                }
              ],
              key: _vm.thumbnail.src,
              staticClass: "nogrow",
              attrs: {
                src: _vm.thumbnail.src,
                alt: _vm.product.name,
                title: _vm.product.name,
                "data-testid": "productImage"
              }
            })
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "w-100 infoProduct" },
            [
              _c(
                "p",
                {
                  staticClass:
                    "product-title m0 lh22 fs14 cl-black weight-500 mb10"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm._f("htmlDecode")(_vm.currentProductName)) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "flex center-xs middle-xs fs12 lh19 attr-help mb20"
                },
                [
                  _c("div", { staticClass: "mr5" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.currentBottleVolume) +
                        "\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.currentAlcoholPercentage) +
                        "\n        "
                    )
                  ])
                ]
              ),
              _vm._v(" "),
              _vm.isCheckoutMode && _vm.showPrice
                ? [
                    _vm.product.special_price &&
                    _vm.product.price &&
                    _vm.isSpecialToDate
                      ? _c("div", { staticClass: "w-100 mt10" }, [
                          _vm.product.special_price &&
                          parseFloat(_vm.product.special_price)
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "price serif weight-600 fs17 cl-black ml4 mr5"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("price")(
                                          _vm.product.special_price
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.product.special_price &&
                          parseFloat(_vm.product.originalPrice)
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "price serif weight-300 fs14 cl-dark-gray price-original"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("price")(
                                          _vm.product.originalPrice
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    !_vm.product.special_price || !_vm.isSpecialToDate
                      ? _c("div", { staticClass: "w-100 mt1p" }, [
                          !_vm.productVipPrice
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "price weight-600 fs18 lh21 cl-black"
                                },
                                [
                                  _vm.configProductPrice
                                    ? [
                                        _vm._v(
                                          "\n              от " +
                                            _vm._s(
                                              _vm._f("price")(
                                                _vm.configProductPrice
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.configProductPrice &&
                                  _vm.simpleProductPrice
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm._f("price")(
                                                _vm.simpleProductPrice
                                              )
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    : _vm._e()
                                ],
                                2
                              )
                            : parseInt(_vm.product.priceInclTax)
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "price serif weight-600 fs14 cl-black",
                                  class: {
                                    "price-original": _vm.productVipPrice
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("price")(
                                          _vm.product.priceInclTax
                                        )
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.productVipPrice
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "price serif weight-600 fs14 cl-red-dark ml4"
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("price")(_vm.productVipPrice)
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ]
                : _vm._e()
            ],
            2
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "product-button weight-600 fs12 lh14 flex center-xs middle-xs ls4 bg-cl-black cl-white uppercase pointer"
        },
        [_vm._v("\n    Купить\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }