import { Module } from 'vuex'
import mutations from './mutations'
import actions from './actions'
import MessageState from '../types/MessageState'

export const module: Module<MessageState, any> = {
  namespaced: true,
  state: {
    message: '',
    serverMessage: '',
    isNotificationHidden: true
  },
  mutations,
  actions
}
