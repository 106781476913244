<template>
  <div class="breadcrumbs fs14 lh22 cl-black flex middle-xs">
    <div v-for="(link, index) in breadcrumbRoutes"
         :key="link.route_link"
         class="flex middle-xs"
    >
      <router-link v-if="!isLastBreadcrumbs(index)"
                   :to="localizedRoute(link.route_link)"
                   class="border-breadcrumbs"
      >
        {{ link.name | htmlDecode }}
      </router-link>
      <div v-if="breadcrumbRoutes[index + 1]" class="arrow mx7" />
      <div v-if="isLastBreadcrumbs(index)" class="pb1">
        {{ link.name | htmlDecode }}
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@vue-storefront/core/compatibility/components/Breadcrumbs'

export default {
  mixins: [Breadcrumbs],
  data () {
    return {
      homeBreadcrumb: {
        name: 'Главная',
        route_link: '/'
      }
    }
  },
  computed: {
    breadcrumbRoutes () {
      let routes = [this.homeBreadcrumb, ...this.routes]
      if (this.activeRoute) {
        routes = [...routes, {name: this.activeRoute}]
      }
      return routes
    }
  },
  methods: {
    isLastBreadcrumbs (index) {
      return this.breadcrumbRoutes.length === index + 1
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '~theme/css/variables/colors';
  @import '~theme/css/helpers/functions/color';
  $red-dark: color(red-dark);
  $breadcrumbs: color(breadcrumbs);
  .arrow-icon {
    vertical-align: middle;
  }
  .arrow {
    width: 4px;
    height: 4px;
    border-top: 1px solid $red-dark;
    border-right: 1px solid $red-dark;
    transform: rotate(45deg);
  }
  .border-breadcrumbs {
    border-bottom: .5px solid $breadcrumbs;
    &:hover {
      color: $breadcrumbs;
      &:after {
        content: none;
      }
    }
  }
</style>
